import { createContext, useContext } from 'react';

import {
  SmoothlyRoutingContextProps,
  SmoothlyRoutingContextValue,
} from './types';

const SmoothlyRoutingContext =
  createContext<SmoothlyRoutingContextValue | null>(null);

export function SmoothlyRoutingContextProvider({
  children,
  value,
}: SmoothlyRoutingContextProps): JSX.Element {
  return (
    <SmoothlyRoutingContext.Provider value={value}>
      {children}
    </SmoothlyRoutingContext.Provider>
  );
}

export function useSmoothlyRoutingContext(): SmoothlyRoutingContextValue {
  const value = useContext(SmoothlyRoutingContext);

  if (!value) {
    throw new Error(
      'useSmoothlyRoutingContext must be used within SmoothlyRoutingContextProvider',
    );
  }

  return value;
}
