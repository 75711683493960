import Step1 from '@src/assets/images/how-to-use/how-to-use-1.jpg';
import Step2 from '@src/assets/images/how-to-use/how-to-use-2.jpg';
import Step3 from '@src/assets/images/how-to-use/how-to-use-3.jpg';

export const assemblyList = [
  {
    id: 1,
    text: 'Order online and write in the comments to the courier that he/she should leave the package in OmniHome.',
    image: Step1,
  },
  {
    id: 2,
    text: 'As soon as the courier scans the QR code on OmniHome and leaves the package you receive notification.',
    image: Step2,
  },
  {
    id: 3,
    text: 'Pick up the package at any time convenient for you.',
    image: Step3,
  },
];
