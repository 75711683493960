import { useEffect } from 'react';

import { useHeaderSettingsContext } from '@src/contexts/HeaderSettingsContext';

export function useSetDefaultHeader() {
  const { setDefaultHeaderSettings } = useHeaderSettingsContext();

  useEffect(() => {
    setDefaultHeaderSettings();
  }, [setDefaultHeaderSettings]);
}
