import { useEffect, useState } from 'react';

import { useAuthContext } from '@src/contexts/AuthContext';
import { useErrorHandlerContext } from '@src/contexts/ErrorHandlerContext';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';

import { getUserInfo } from '../utils/getUserInfo';
import { useRecordUserInfoResponse } from './useRecordUserInfo';

export function useGetUserInfo(): boolean {
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuthContext();
  const {
    userInfo: { firstName },
  } = useUserInfoContext();
  const { handleError } = useErrorHandlerContext();

  const recordUserInfoResponse = useRecordUserInfoResponse();

  useEffect(() => {
    // If we already have user info, we don't need to fetch it again
    if (firstName) return;

    setIsLoading(true);
    getUserInfo(token)
      .then((userInfo) => {
        recordUserInfoResponse(userInfo);
      })
      .catch((error) => handleError(error.message))
      .finally(() => setIsLoading(false));
  }, [token, firstName, handleError, recordUserInfoResponse]);

  return isLoading;
}
