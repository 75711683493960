import { useCallback, useState } from 'react';

import { SignInData } from '@src/api/types';
import { authUser } from '@src/api/utils/authUser';
import { useAuthContext } from '@src/contexts/AuthContext';
import { useErrorHandlerContext } from '@src/contexts/ErrorHandlerContext';
import { useSmoothlyRoutingContext } from '@src/contexts/SmoothlyRoutingContext';
import { Path } from '@src/types/routes';

interface UseSignInReturns {
  isLoading: boolean;
  authUserHandler: (singInData: SignInData) => void;
}

export function useSignIn(): UseSignInReturns {
  const [isLoading, setIsLoading] = useState(false);

  const { saveToken } = useAuthContext();
  const { handleError } = useErrorHandlerContext();
  const { startSmoothlyRouting } = useSmoothlyRoutingContext();

  const authUserHandler = useCallback(
    async (singInData: SignInData) => {
      setIsLoading(true);

      try {
        const { access_token: token } = await authUser(singInData);
        saveToken(token);

        startSmoothlyRouting(Path.user);
      } catch (error: AnyType) {
        handleError(error.message);
      }
      setIsLoading(false);
    },
    [saveToken, handleError, startSmoothlyRouting],
  );

  return {
    isLoading,
    authUserHandler,
  };
}
