import PhoneNumber from '@src/components/FlowManager/components/PhoneNumber';
import FlowManager from '@src/components/FlowManager/FlowManager';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useSetDefaultHeader } from '@src/hooks/useSetDefaultHeader';
import { Path } from '@src/types/routes';

import { ERegistrationStep } from './types';

export default function Registration(): JSX.Element {
  const { tCommon } = useTranslations();
  useSetDefaultHeader();

  return (
    <FlowManager title={tCommon('registration')}>
      <PhoneNumber
        nextRoute={`${Path.registration}/${ERegistrationStep.confirmationCode}`}
      />
    </FlowManager>
  );
}
