import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { useTranslations } from '@src/contexts/TranslationContext';

import { assemblyList, devicePackage } from './constants';

import {
  AssemblyItem,
  AssemblyWrapper,
  ComplectItem,
  ComplectWrapper,
  Image,
  InstructionItem,
  InstructionWrapper,
  List,
  ListItem,
  Text,
  Title,
} from './styled';

export default function AssemblyOneCell() {
  const { t } = useTranslations('omniHomeLaunching');

  return (
    <InstructionWrapper>
      <InstructionItem>
        <Title>{t('devicePackage')}</Title>
        <ComplectWrapper>
          {devicePackage.map(({ id, image }) => {
            return (
              <ComplectItem key={id} data-marker={id}>
                <LazyLoadComponent>
                  <Image src={image} />
                </LazyLoadComponent>
              </ComplectItem>
            );
          })}
        </ComplectWrapper>

        <List>
          {devicePackage.map(({ id, text }) => {
            return <ListItem key={id}>{text}</ListItem>;
          })}
        </List>
      </InstructionItem>
      <InstructionItem>
        <Title>{t('assemblyInstructions')}</Title>
        <AssemblyWrapper>
          {assemblyList.map(({ id, text, image }) => {
            return (
              <AssemblyItem key={id} data-marker={id}>
                <Text>{text}</Text>
                <LazyLoadComponent>
                  <Image src={image} />
                </LazyLoadComponent>
              </AssemblyItem>
            );
          })}
        </AssemblyWrapper>
      </InstructionItem>
    </InstructionWrapper>
  );
}
