import { styled } from 'styled-components';

import { applyAnimation, slideBottom, slideLeft } from '@src/theme/animation';
import {
  ContentContainer as BaseContainer,
  FieldContainer,
} from '@src/theme/commonElements';

export const ContentContainer = styled(BaseContainer)`
  justify-content: flex-start;
`;

export const Title = styled.h1`
  width: 100%;
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
  margin-bottom: ${({ theme }): string => theme.spacing(3)};
  text-align: left;
  text-transform: uppercase;
  ${applyAnimation(slideBottom)};
`;

export const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${FieldContainer} {
    ${applyAnimation(slideLeft)};

    &:nth-child(1) {
      animation-delay: 0.1s;
    }
    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.3s;
    }
    &:nth-child(4) {
      animation-delay: 0.4s;
    }
    &:nth-child(5) {
      animation-delay: 0.5s;
    }
    &:nth-child(6) {
      animation-delay: 0.6s;
    }
    &:nth-child(7) {
      animation-delay: 0.7s;
    }
    &:nth-child(8) {
      animation-delay: 0.8s;
    }
  }
`;
