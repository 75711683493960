import styled, { css } from 'styled-components';

import { applyAnimation, slideBottom, slideTop } from '@src/theme/animation';

import { ButtonsCollection } from '@src/components/Button/styled';

const textStyle = css`
  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  letter-spacing: ${({ theme }): string => theme.typography.letterSpacing.xxs};
`;

const countElement = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }): string => theme.palette.background.special};

  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.s};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
  letter-spacing: ${({ theme }): string => theme.typography.letterSpacing.xs};
`;

export const TopWrapper = styled.div`
  background-color: ${({ theme }): string => theme.palette.background.primary};
  padding-bottom: ${({ theme: { spacing } }): string => spacing(4.5)};
`;

export const Title = styled.h1`
  font-family: ${({ theme }): string => theme.typography.fontFamily.secondary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  color: ${({ theme }): string => theme.palette.text.primary};
  letter-spacing: ${({ theme }): string => theme.typography.letterSpacing.l};
  text-transform: uppercase;

  margin-bottom: ${({ theme }): string => theme.spacing(3)};
`;

export const TextWrapper = styled.div`
  padding: 0 ${({ theme: { spacing } }): string => spacing(2.5)};
  ${applyAnimation(slideBottom)};

  ${Title} {
    margin-bottom: ${({ theme }): string => theme.spacing(1.5)};
  }
`;

export const Description = styled.p`
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const ButtonsWrapper = styled(ButtonsCollection)`
  margin-top: ${({ theme }): string => theme.spacing(3)};
  ${applyAnimation(slideTop)};
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
`;

export const Text = styled.div`
  ${textStyle};
  margin-bottom: ${({ theme }): string => theme.spacing(3.75)};
`;

export const AssemblyItem = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-bottom: ${({ theme }): string => theme.spacing(3)};
  }

  &::before {
    content: attr(data-marker);
    ${countElement}
  }

  & ${Text} {
    padding-left: ${({ theme }): string => theme.spacing(5)};
  }
`;

export const FinalStep = styled.div`
  background-color: ${({ theme }): string =>
    theme.palette.background.secondary};
  padding: ${({ theme: { spacing } }): string =>
    `${spacing(4.5)} ${spacing(2.5)}`};

  ${Title}, ${Description} {
    color: ${({ theme }): string => theme.palette.text.white};
  }
`;
