import { createContext, useContext } from 'react';

import { ContextProps } from '../types';
import { useNotificationSettings } from './hooks/useNotificationSettings';
import { ContextData } from './types';

import { CloseIcon, Message, Notification } from './styled';

const Context = createContext<ContextData | null>(null);

export function NotificationsContextProvider({ children }: ContextProps) {
  const { value, isActive, notification, closeNotification } =
    useNotificationSettings();

  return (
    <Context.Provider value={value}>
      <Notification
        $status={notification.status}
        $isActive={isActive}
        aria-label="Notification window"
      >
        <Message>{notification.value}</Message>
        <CloseIcon
          role="button"
          aria-label="Close notification window"
          onClick={closeNotification}
        />
      </Notification>
      {children}
    </Context.Provider>
  );
}

export function useNotifications(): ContextData {
  const contextData = useContext(Context);
  if (!contextData)
    throw new Error(
      'Please use hook useNotifications inside NotificationsContext provider',
    );
  return contextData;
}
