import { useLayoutEffect, useState } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';

import WithImageBg from '@src/components/WithImageBg/WithImageBg';
import { useAuthContext } from '@src/contexts/AuthContext';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useSmoothlyRoutingContext } from '@src/contexts/SmoothlyRoutingContext';
import { useTranslations } from '@src/contexts/TranslationContext';
import { Path } from '@src/types/routes';

export default function Main(): JSX.Element {
  const [isAnimatedMainPage, setIsAnimatedMainPage] = useState(true);
  const [isAnimatedAuthPage, setIsAnimatedAuthPage] = useState(true);

  const isAnimated = !!useMatch('/');

  const { deviceInfo, deviceContent } = useDeviceInfoContext();
  const { t, tCommon } = useTranslations('omniHomeLaunching');
  const navigate = useNavigate();
  const { token } = useAuthContext();
  const { startSmoothlyRouting } = useSmoothlyRoutingContext();

  const isLoading = !deviceInfo;
  const { mainImage } = deviceContent;

  function onStart() {
    startSmoothlyRouting(Path.instruction);
  }

  function getSecondaryButtonValues() {
    return token
      ? {
          title: tCommon('personalAccount'),
          action: () => navigate(Path.user),
        }
      : {
          title: tCommon('authorization'),
          action: () => navigate(Path.authorization),
        };
  }

  useLayoutEffect(() => {
    setIsAnimatedMainPage(isAnimated);
    setIsAnimatedAuthPage(isAnimated);
    // We need to disable this rule because we need to run this effect only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <WithImageBg
        buttonAction={onStart}
        buttonTitle={t('firstSetup')}
        secondButtonTitle={getSecondaryButtonValues().title}
        secondButtonAction={getSecondaryButtonValues().action}
        image={mainImage}
        isAnimated={isAnimatedMainPage}
        isLoading={isLoading}
      />
      <Outlet
        context={{ isAnimated: isAnimatedAuthPage, setIsAnimatedAuthPage }}
      />
    </>
  );
}
