import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import ConfirmationCode from '@src/components/FlowManager/components/ConfirmationCode';
import { ConfirmationCodeFlow } from '@src/components/FlowManager/components/ConfirmationCode/types';
import {
  ERegistrationStep,
  RegistrationSteps,
} from '@src/pages/Registration/types';
import { Path } from '@src/types/routes';

import Address from '../components/Address';
import PasswordCreation from '../components/PasswordCreation/PasswordCreation';
import PersonalData from '../components/PersonalData';

export function useRegistrationStepsHandler(): ReactNode {
  const { registrationStep } = useParams() as {
    registrationStep: ERegistrationStep;
  };

  const registrationSteps: RegistrationSteps = {
    [ERegistrationStep.confirmationCode]: (
      <ConfirmationCode
        nextRoute={`${Path.registration}/${ERegistrationStep.personalData}`}
        flow={ConfirmationCodeFlow.registration}
      />
    ),
    [ERegistrationStep.personalData]: <PersonalData />,
    [ERegistrationStep.address]: <Address />,
    [ERegistrationStep.password]: <PasswordCreation />,
  };

  return registrationSteps[registrationStep];
}
