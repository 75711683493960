import { css, keyframes } from 'styled-components';
import { Keyframes } from 'styled-components/dist/types';

import { ROUTING_DELAY } from '@src/constants/routing';

import { AnimatedOpacity } from './commonTypes';

export const appearing = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const fading = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const slideBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const slideTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const slideLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export function applyAnimation(animation: Keyframes) {
  return css`
    animation: ${ROUTING_DELAY}ms ease-in-out ${animation};
    animation-fill-mode: both;
  `;
}

export const animateOpacity =
  (behavior: 'fade' | 'appear') =>
  ({ $isStarted }: AnimatedOpacity) => {
    if ($isStarted) {
      return css`
        ${applyAnimation(behavior === 'appear' ? appearing : fading)}
      `;
    }
    return css`
      opacity: ${behavior === 'appear' ? 0 : 1};
    `;
  };

export const buttonClickAnimation = css`
  &:active {
    top: 1px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 4em;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition-duration: 0.5s;
    box-shadow: 0 0 10px 20px ${({ theme }): string => theme.palette.primary};
  }

  &:active:after {
    box-shadow: 0 0 0 0 ${({ theme }): string => theme.palette.primary};
    position: absolute;
    border-radius: 4em;
    left: 0;
    top: 0;
    opacity: 1;
    transition-duration: 0s;
  }
`;
