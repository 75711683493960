import { request } from '@src/api';
import { UserInfoResponse } from '@src/api/types';

export async function getUserInfo(token: string): Promise<UserInfoResponse> {
  const { data } = await request<UserInfoResponse>({
    url: 'user/company/',
    method: 'GET',
    headers: {
      Authorization: token,
    },
  });

  return data;
}
