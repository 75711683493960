export interface CountdownProps {
  time: number;
}

export enum Sizes {
  strokeM = 12,
  strokeS = 8,
}

export interface CircleDimensions {
  circleSize: number;
  circlePosition: number;
  circleRadius: number;
  circleLength: number;
  strokeWidth: number;
  strokeDashoffset: number;
}
