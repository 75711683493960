import {
  ChangeEvent,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useTranslations } from '@src/contexts/TranslationContext';

import Input from '../Input';
import { InputRef } from '../Input/types';
import { PasswordProps, PasswordRef } from './types';

import { CodeText, LockIcon, PasswordContainer } from './styled';

const Password = forwardRef<PasswordRef, PasswordProps>(
  ({ minPasswordLength = 8, onChange }, ref) => {
    const [password, setPassword] = useState('');
    const [repeatedPassword, setRepeatedPassword] = useState('');

    const { tCommon, tErrors } = useTranslations();

    const passwordRef = useRef<InputRef>(null);
    const repeatedPasswordRef = useRef<InputRef>(null);

    function onPasswordChange(event: ChangeEvent<HTMLInputElement>) {
      const { value } = event.target;
      setPassword(value);
      onChange?.(value);
    }

    function onRepeatedPasswordChange(event: ChangeEvent<HTMLInputElement>) {
      const { value } = event.target;
      setRepeatedPassword(value);
    }

    useImperativeHandle(ref, () => {
      return {
        checkPassword: () => {
          const isPasswordValid = passwordRef.current?.isPasswordValid();

          if (!isPasswordValid) return;

          const isEquals = password === repeatedPassword;

          if (isEquals) {
            return password;
          }
          repeatedPasswordRef.current?.setInputError(
            tCommon('passwordsDoNotMatch'),
          );
        },
      };
    });

    return (
      <PasswordContainer>
        <Input
          ref={passwordRef}
          value={password}
          label={tCommon('createPassword')}
          onChange={onPasswordChange}
          minPasswordLength={minPasswordLength}
          isPassword
          required
        />

        <Input
          ref={repeatedPasswordRef}
          value={repeatedPassword}
          label={tCommon('repeatPassword')}
          onChange={onRepeatedPasswordChange}
          isPassword
          required
        />
        <CodeText>
          <LockIcon />
          {tErrors('password')}
        </CodeText>
      </PasswordContainer>
    );
  },
);

export default Password;
