import styled from 'styled-components';

import { animateOpacity } from '@src/theme/animation';

import { CoverProps, MainWrapperProps } from './types';

function setBgColor({ theme, $nextPageBg }: MainWrapperProps) {
  if ($nextPageBg === 'dark') {
    return theme.palette.background.secondary;
  }
  if ($nextPageBg === 'light') {
    return theme.palette.background.white;
  }
  return theme.palette.background.primary;
}

export const MainWrapper = styled.main<MainWrapperProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${setBgColor};
`;

export const Cover = styled.div<CoverProps>`
  position: fixed;
  pointer-events: none;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: ${setBgColor};
  ${animateOpacity('appear')};
`;
