import { useEffect } from 'react';

import Button from '@src/components/Button';
import { useAuthContext } from '@src/contexts/AuthContext';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useHeaderSettingsContext } from '@src/contexts/HeaderSettingsContext';
import { useSmoothlyRoutingContext } from '@src/contexts/SmoothlyRoutingContext';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useSetDefaultHeader } from '@src/hooks/useSetDefaultHeader';
import { Path } from '@src/types/routes';

import {
  ButtonsWrapper,
  Description,
  FinalStep,
  Image,
  TextWrapper,
  Title,
  TopWrapper,
} from './styled';

export default function Instruction(): JSX.Element {
  const { token } = useAuthContext();
  const { deviceContent } = useDeviceInfoContext();
  const { t, tCommon } = useTranslations('omniHomeLaunching');
  const { startSmoothlyRouting } = useSmoothlyRoutingContext();
  useSetDefaultHeader();
  const { setupHeaderSettings } = useHeaderSettingsContext();

  function getFinalStepValues() {
    return token
      ? {
          title: t('title'),
          description: t('description'),
          buttonText: tCommon('mainPage'),
          action: () => startSmoothlyRouting(Path.user),
        }
      : {
          title: t('finalSetup'),
          description: t('finalSetupDescription'),
          buttonText: tCommon('registration'),
          action: () => startSmoothlyRouting(Path.registration),
        };
  }

  const { assembly, instructionImage } = deviceContent;
  const { title, description, buttonText, action } = getFinalStepValues();

  useEffect(() => {
    setupHeaderSettings({
      isVisibleBackButton: true,
      isActiveRightButton: true,
      RightButtonTitle: tCommon('support'),
      RightButtonAction: () => {
        setTimeout(() => {
          window
            .open(
              'https://omnicampus.atlassian.net/servicedesk/customer/portal/9',
            )
            ?.focus();
        });
      },
    });
  }, [setupHeaderSettings, tCommon]);

  return (
    <>
      <TopWrapper>
        <Image src={instructionImage} />
        <TextWrapper>
          <Title>{t('congratulationsTitle')}</Title>
          <Description>{t('congratulationsDescription')}</Description>
        </TextWrapper>
      </TopWrapper>
      {assembly}
      <FinalStep>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ButtonsWrapper>
          <Button variant="contained" mode="primary" onClick={action}>
            {buttonText}
          </Button>
        </ButtonsWrapper>
      </FinalStep>
    </>
  );
}
