import {
  ChangeEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import Input from '@src/components/Input';
import { InputRef } from '@src/components/Input/types';
import { countries } from '@src/components/PhoneInput/components/CountryPicker/countries';
import { CountryFullData } from '@src/components/PhoneInput/components/CountryPicker/types';
import { useUserDataFromIP } from '@src/components/PhoneInput/hooks/useUserDataFromIP';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';

import { inputId } from '../constants';
import { Id, SignInData } from '../types';

interface UseAuthInputsRenderReturns {
  signInData: SignInData;
  checkInputs: () => boolean | undefined;
  renderInputs: () => ReactNode;
}

export function useAuthInputs(): UseAuthInputsRenderReturns {
  const { tCommon } = useTranslations();

  const [signInData, setSignInData] = useState<SignInData>({
    number: '',
    password: '',
  });

  const phoneRef = useRef<InputRef>(null);
  const passwordRef = useRef<InputRef>(null);
  const {
    userInfo: { phone },
  } = useUserInfoContext();
  const getCountryCodeFromIP = useUserDataFromIP();
  const checkInputs = useCallback((): boolean | undefined => {
    if (!phoneRef.current || !passwordRef.current) return;

    const isPhoneValid = phoneRef.current.isPhoneValid();
    const isPasswordEmpty = passwordRef.current.isEmpty();

    return !!(isPhoneValid && !isPasswordEmpty);
  }, []);

  function onChangeInput({ target }: ChangeEvent<HTMLInputElement>) {
    const id = target.id as Id;
    const { value } = target;

    setSignInData((prev) => ({ ...prev, [id]: value }));
  }

  // Temporary solution. This is duplicate logic CountryPicker.
  // TODO: Delete after extending the CountryPicker functionality.
  useEffect(() => {
    (async () => {
      if (phone) {
        return setSignInData((prev) => ({
          ...prev,
          number: phone,
        }));
      }

      const countryCode = (await getCountryCodeFromIP())?.country || 'UK';
      const { dialCode } = countries.find(
        ({ code }) => code === countryCode,
      ) as CountryFullData;

      setSignInData((prev) => ({
        ...prev,
        number: dialCode,
      }));
    })();
  }, [phone, getCountryCodeFromIP]);

  function renderInputs(): ReactNode {
    return Object.values(inputId).map((value) => {
      const id = value as Id;
      return (
        <Input
          id={id}
          key={id}
          ref={id === 'number' ? phoneRef : passwordRef}
          type={id === 'number' ? 'tel' : undefined}
          label={tCommon(id)}
          onChange={onChangeInput}
          value={signInData[id]}
          isPassword={id === 'password'}
          required
        />
      );
    });
  }

  return {
    signInData,
    renderInputs,
    checkInputs,
  };
}
