import { CountryCode } from '@src/components/PhoneInput/components/CountryPicker/types';

export interface UserInfo {
  deviceUid: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  countryName: string;
  city: string;
  street: string;
  house: string;
  addressDescription: string;
  confirmationCode: string;
  country?: CountryCode;
}

export enum ActionType {
  setAll = 'setAll',
  setFirstName = 'setFirstName',
  setLastName = 'setLastName',
  setPhone = 'setPhone',
  setEmail = 'setEmail',
  setPassword = 'setPassword',
  setCountry = 'setCountry',
  setCity = 'setCity',
  setStreet = 'setStreet',
  setHouse = 'setHouse',
  setAddressDescription = 'setAddressDescription',
  setDeviceUid = 'setDeviceUid',
  setConfirmationCode = 'setConfirmationCode',
}

export type CommonActions = keyof Omit<
  typeof ActionType,
  'setCountry' | 'setAll'
>;

export type Action =
  | {
      type: CommonActions;
      payload: string;
    }
  | {
      type: ActionType.setCountry;
      payload: {
        countryName: string;
        country: CountryCode;
      };
    }
  | {
      type: ActionType.setAll;
      payload: Omit<UserInfo, 'confirmationCode' | 'password' | 'deviceUid'>;
    };

export interface ButtonState {
  text: string | number;
  isActive?: boolean;
  isLoading?: boolean;
  buttonAction?: () => void;
}

export interface UserInfoContextValue {
  userInfo: UserInfo;
  dispatch: React.Dispatch<Action>;
}
