import Assembly1 from '@src/assets/images/assembly-one-cell/assembly-1.jpg';
import Assembly2 from '@src/assets/images/assembly-one-cell/assembly-2.jpg';
import Assembly3 from '@src/assets/images/assembly-one-cell/assembly-3.jpg';
import Assembly4 from '@src/assets/images/assembly-one-cell/assembly-4.jpg';
import Assembly5 from '@src/assets/images/assembly-one-cell/assembly-5.jpg';
import Assembly6 from '@src/assets/images/assembly-one-cell/assembly-6.jpg';
import devicePackage1 from '@src/assets/images/assembly-one-cell/package/package-1.jpg';
import devicePackage2 from '@src/assets/images/assembly-one-cell/package/package-2.jpg';
import devicePackage3 from '@src/assets/images/assembly-one-cell/package/package-3.jpg';
import devicePackage4 from '@src/assets/images/assembly-one-cell/package/package-4.jpg';
import devicePackage5 from '@src/assets/images/assembly-one-cell/package/package-5.jpg';
import devicePackage6 from '@src/assets/images/assembly-one-cell/package/package-6.jpg';

import { AssemblyItem } from './type';

export const devicePackage: AssemblyItem[] = [
  {
    id: 1,
    text: 'OmniHome automated mailbox',
    image: devicePackage1,
  },
  {
    id: 2,
    text: 'Connectivity Antenna',
    image: devicePackage2,
  },
  {
    id: 3,
    text: 'Service key',
    image: devicePackage3,
  },
  {
    id: 4,
    text: 'Hex S4',
    image: devicePackage4,
  },
  {
    id: 5,
    text: 'Hex S2.5',
    image: devicePackage5,
  },
  {
    id: 6,
    text: 'User manual',
    image: devicePackage6,
  },
];

export const assemblyList: AssemblyItem[] = [
  {
    id: 1,
    text: 'Open OmniHome with the emergency opening key',
    image: Assembly1,
  },
  {
    id: 2,
    text: 'Unscrew the shipping screws from the bottom and remove the legs from the OmniHome',
    image: Assembly2,
  },
  {
    id: 3,
    text: 'Screw the legs from underneath with four screws.',
    image: Assembly3,
  },
  {
    id: 4,
    text: 'Screw the legs from underneath with four screws.',
    image: Assembly4,
  },
  {
    id: 5,
    text: 'Insert the SIM card into the card holder (optional if not preinstalled)',
    image: Assembly5,
  },
  {
    id: 6,
    text: 'Plug in the connector into the battery (optional if not preinstalled)',
    image: Assembly6,
  },
];
