import { USER_DATA } from '../constants';
import { Action, ActionType, UserInfo } from '../types';

const cachedData = sessionStorage.getItem(USER_DATA);

export const savedUserInfo: null | UserInfo =
  cachedData && JSON.parse(cachedData);

export const initialUserInfo: UserInfo = {
  deviceUid: '',
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  password: '',
  countryName: '',
  city: '',
  street: '',
  addressDescription: '',
  house: '',
  confirmationCode: '',
  country: undefined,
};

export function reducer(state: UserInfo, { type, payload }: Action) {
  let updatedState = state;

  if (type === ActionType.setFirstName) {
    updatedState = { ...state, firstName: payload };
  }
  if (type === ActionType.setLastName) {
    updatedState = { ...state, lastName: payload };
  }
  if (type === ActionType.setPhone) {
    updatedState = { ...state, phone: payload };
  }
  if (type === ActionType.setEmail) {
    updatedState = { ...state, email: payload };
  }
  if (type === ActionType.setPassword) {
    updatedState = { ...state, password: payload };
  }
  if (type === ActionType.setCountry) {
    updatedState = {
      ...state,
      countryName: payload.countryName,
      country: payload.country,
    };
  }
  if (type === ActionType.setCity) {
    updatedState = { ...state, city: payload };
  }
  if (type === ActionType.setStreet) {
    updatedState = { ...state, street: payload };
  }
  if (type === ActionType.setHouse) {
    updatedState = { ...state, house: payload };
  }
  if (type === ActionType.setAddressDescription) {
    updatedState = { ...state, addressDescription: payload };
  }
  if (type === ActionType.setDeviceUid) {
    updatedState = { ...state, deviceUid: payload };
  }
  if (type === ActionType.setConfirmationCode) {
    updatedState = { ...state, confirmationCode: payload };
  }
  if (type === ActionType.setAll) {
    updatedState = { ...state, ...payload };
  }

  sessionStorage.setItem(USER_DATA, JSON.stringify(updatedState));
  return updatedState;
}
