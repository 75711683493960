import styled from 'styled-components';

import LockSVG from '@src/assets/icons/lock.svg';

export const PasswordContainer = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CodeText = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }): string => theme.palette.text.primary};
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const LockIcon = styled(LockSVG)`
  margin-right: ${({ theme }): string => theme.spacing(1)};
`;
