import { useEffect } from 'react';

import PhoneInput from '@src/components/PhoneInput';
import TextArea from '@src/components/TextArea';
import { useHeaderSettingsContext } from '@src/contexts/HeaderSettingsContext';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';
import { Loader } from '@src/theme/commonElements';

import ButtonChangePassword from './components/ButtonChangePassword';
import { useGetUserInfo } from './hooks/useGetUserInfo';
import { useInputs } from './hooks/useInputs';
import { useUpdateUserInfo } from './hooks/useUpdateUserInfo';

import { ContentContainer, InputsWrapper, Title } from './styled';

export default function UserInfo(): JSX.Element {
  const { userInfo } = useUserInfoContext();
  const { tCommon } = useTranslations();
  const { setupHeaderSettings } = useHeaderSettingsContext();

  const {
    phoneRef,
    descriptionRef,
    localUserInfo,
    isActiveSaveButton,
    onPhoneChange,
    onDescriptionChange,
    renderInputs,
    checkInputs,
  } = useInputs();

  const isGettingData = useGetUserInfo();
  const { isUpdating, updateUserInfoHandler } = useUpdateUserInfo(
    localUserInfo,
    checkInputs,
  );

  const isLoading = isGettingData || isUpdating;

  useEffect(() => {
    setupHeaderSettings({
      isVisibleBackButton: true,
      isActiveRightButton: isActiveSaveButton,
      RightButtonTitle: tCommon('saveChanges'),
      RightButtonAction: updateUserInfoHandler,
    });
  }, [
    isActiveSaveButton,
    localUserInfo,
    tCommon,
    updateUserInfoHandler,
    setupHeaderSettings,
  ]);

  return (
    <ContentContainer>
      <Title>{tCommon('myInfo')}</Title>
      <Loader $isLoading={isLoading} />
      {!isLoading && (
        <InputsWrapper>
          <PhoneInput
            ref={phoneRef}
            initialCountry={userInfo.country}
            initialNumber={userInfo.phone}
            onChange={onPhoneChange}
          />
          {renderInputs()}
          <TextArea
            label={tCommon('addressDescription')}
            placeholder={tCommon('addressDescriptionPlaceholder')}
            value={localUserInfo.addressDescription}
            onChange={onDescriptionChange}
            ref={descriptionRef}
            required
          />
          <ButtonChangePassword />
        </InputsWrapper>
      )}
    </ContentContainer>
  );
}
