import { useCallback, useEffect } from 'react';

import FlowManager from '@src/components/FlowManager/FlowManager';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useSmoothlyRoutingContext } from '@src/contexts/SmoothlyRoutingContext';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useSignIn } from '@src/hooks/useSignIn';
import { Path } from '@src/types/routes';

import { ERecoveryStep } from '../PasswordRecovery/types';
import { useAuthInputs } from './hooks/useAuthInputs';

import {
  AuthFields,
  ForgotPasswordTitle,
  ForgotPasswordWrapper,
  Lock,
  MiddleWrapper,
} from './styled';

export default function Authorization(): JSX.Element {
  const { tCommon } = useTranslations();
  const { startSmoothlyRouting } = useSmoothlyRoutingContext();

  const { setButtonState } = useFlowManagerContext();
  const { signInData, renderInputs, checkInputs } = useAuthInputs();
  const { isLoading, authUserHandler } = useSignIn();

  const buttonAction = useCallback(() => {
    const isFieldsValid = checkInputs();
    if (isFieldsValid) {
      authUserHandler(signInData);
    }
  }, [signInData, authUserHandler, checkInputs]);

  function onForgotPassword() {
    startSmoothlyRouting(
      `${Path.passwordRecovery}/${ERecoveryStep.phoneNumber}`,
    );
  }

  useEffect(() => {
    setButtonState({
      text: tCommon('signIn'),
      isLoading,
      buttonAction,
    });
    // We need become Warning: Maximum update depth exceeded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, signInData, tCommon, setButtonState]);

  return (
    <FlowManager title={tCommon('authorization')}>
      <MiddleWrapper>
        <AuthFields>{renderInputs()}</AuthFields>
        <ForgotPasswordWrapper role="button" onClick={onForgotPassword}>
          <Lock />
          <ForgotPasswordTitle>{tCommon('forgotPassword')}</ForgotPasswordTitle>
        </ForgotPasswordWrapper>
      </MiddleWrapper>
    </FlowManager>
  );
}
