import { createContext, useCallback, useContext, useMemo } from 'react';

import { useNotifications } from '../NotificationsContext';
import { useTranslations } from '../TranslationContext';
import { ContextProps } from '../types';
import { ErrorHandlerContextValue, ErrorType } from './types';

const ErrorHandlerContext = createContext<ErrorHandlerContextValue | null>(
  null,
);

export function ErrorHandlerContextProvider({
  children,
}: ContextProps): JSX.Element {
  const { tErrors } = useTranslations();

  const { setNotification } = useNotifications();

  const getErrorText = useCallback(
    (errorType: ErrorType): string => {
      if (errorType === ErrorType.phoneNumberAlreadyExist) {
        return tErrors('userAlreadyExist');
      }
      if (errorType === ErrorType.userNotRegistered) {
        return tErrors('userNotRegistered');
      }
      if (errorType === ErrorType.incorrectLoginOrPassword) {
        return tErrors('incorrectLoginOrPassword');
      }
      if (errorType === ErrorType.phoneNumberNotValid) {
        return tErrors('invalidPhoneNumber');
      }
      if (errorType === ErrorType.loadingError) {
        return tErrors('loadingError');
      }
      if (errorType === ErrorType.toMainPage) {
        return tErrors('innerError');
      }
      if (errorType === ErrorType.invalidConfirmationCode) {
        return tErrors('invalidConfirmationCode');
      }
      if (errorType === ErrorType.incorrectPassword) {
        return tErrors('incorrectPassword');
      }
      if (errorType === ErrorType.deviceUidDoesNotExist) {
        return tErrors('deviceUidDoesNotExist');
      }
      return tErrors('somethingWentWrong');
    },
    [tErrors],
  );

  const handleError = useCallback(
    (errorType: ErrorType) => {
      setNotification({
        status: 'error',
        value: getErrorText(errorType),
      });
    },
    [setNotification, getErrorText],
  );

  const value = useMemo(
    () => ({
      handleError,
    }),
    [handleError],
  );

  return (
    <ErrorHandlerContext.Provider value={value}>
      {children}
    </ErrorHandlerContext.Provider>
  );
}

export function useErrorHandlerContext(): ErrorHandlerContextValue {
  const value = useContext(ErrorHandlerContext);

  if (value) return value;

  throw new Error(
    'Please, use useErrorHandlerContext inside ErrorHandlerContextProvider',
  );
}
