import styled from 'styled-components';

import ChevronSVG from '@src/assets/icons/chevron.svg';
import { applyAnimation, slideLeft } from '@src/theme/animation';

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2.5)};
  border-radius: 1px;
  border: 1px solid ${({ theme }) => theme.palette.grey[100]};

  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.s};
  font-weight: ${({ theme }) => theme.typography.fontWeight[700]};

  text-align: left;
  background-color: transparent;

  ${applyAnimation(slideLeft)};
  animation-delay: 0.9s;
`;

export const ChevronIcon = styled(ChevronSVG)`
  transform: rotate(180deg);
`;
