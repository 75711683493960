import { useSmoothlyRoutingContext } from '@src/contexts/SmoothlyRoutingContext';
import { useTranslations } from '@src/contexts/TranslationContext';
import { Path } from '@src/types/routes';

import { Button, ChevronIcon } from './styled';

export default function ButtonChangePassword(): JSX.Element {
  const { tCommon } = useTranslations();
  const { startSmoothlyRouting } = useSmoothlyRoutingContext();

  return (
    <Button
      type="button"
      onClick={() => startSmoothlyRouting(Path.changePassword)}
    >
      {tCommon('changePassword')}
      <ChevronIcon />
    </Button>
  );
}
