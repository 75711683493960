import styled, { css } from 'styled-components';

import ShowPasswordSVG from '@src/assets/icons/eye-off-outline.svg';
import HidePasswordSVG from '@src/assets/icons/eye-outline.svg';

import { TextInputProps } from './types';

const textCommon = css`
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.m};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
`;

function applyInputStyles({ $isPassword }: TextInputProps) {
  return $isPassword
    ? css`
        padding-right: 40px;
      `
    : css`
        padding-right: 0;
      `;
}

export const ShowPasswordIcon = styled(ShowPasswordSVG)``;
export const HidePasswordIcon = styled(HidePasswordSVG)``;

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(1.5)};
  border-bottom: 1px solid ${({ theme }): string => theme.palette.grey[100]};
`;

export const PhoneCode = styled.span`
  ${textCommon};
`;

export const TextInput = styled.input<TextInputProps>`
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  ${textCommon};
  ${applyInputStyles};
`;

export const Button = styled.button`
  position: absolute;
  right: 0;
  bottom: 7px;
  border: none;
  outline: none;
  background-color: transparent;
`;
