import { ChangeEvent } from 'react';

import { useApplyCommonState } from '@src/contexts/FlowManagerContext/hooks/useApplyCommonState';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';
import { CommonActions } from '@src/contexts/UserInfoContext/types';

export function useSimpleFieldsReg(
  isStarted: boolean,
  onNextHandler: () => void,
) {
  const { dispatch } = useUserInfoContext();

  useApplyCommonState({
    isStarted,
    buttonAction: onNextHandler,
  });

  return (action: CommonActions) =>
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      dispatch({
        type: action,
        payload: event.target.value,
      });
    };
}
