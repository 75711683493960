import { request } from '@src/api';

import { RecoverPasswordRequest } from '../types';

interface RecoverPasswordArgs {
  token: string;
  password: string;
}

export async function recoverPassword({
  token,
  password,
}: RecoverPasswordArgs): Promise<void> {
  await request<never, RecoverPasswordRequest>({
    url: 'users/current/',
    method: 'PATCH',
    data: {
      password,
    },
    headers: {
      Authorization: token,
    },
  });
}
