import styled, { css, keyframes } from 'styled-components';

import { StyleProps } from './types';

const load = keyframes`
  0% {
    transform: rotate(0deg);
  };
  100% {
    transform: rotate(360deg);
  };
`;

function applyBorderColor({ $mode }: StyleProps) {
  return $mode === 'primary'
    ? css`
        border-color: #fcff2f6d;
        border-left-color: ${({ theme }) => theme.palette.primary};
      `
    : css`
        border-color: #6f6f6f;
        border-left-color: ${({ theme }) => theme.palette.secondary};
      `;
}

export const Loading = styled.div<StyleProps>`
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation: ${load} 1.1s infinite linear;

  border-width: 5px;
  border-style: solid;
  ${applyBorderColor};

  &,
  &::after {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
`;
