import { request } from '@src/api';
import { UpdateQRRequest } from '@src/api/types';

export async function updateQR(deviceUid: string): Promise<object> {
  const { data } = await request<object, UpdateQRRequest>({
    url: '/device/qr-code/update',
    method: 'POST',
    data: {
      device_uid: deviceUid,
    },
  });

  return data;
}
