import { RefObject, useCallback } from 'react';

import { InputRef } from '@src/components/Input/types';
import { useTranslations } from '@src/contexts/TranslationContext';

import { Country } from '../components/CountryPicker/types';

export function useCheckIsError(
  inputRef: RefObject<InputRef>,
  numberValue: string,
  countryData?: Country,
) {
  const { tCommon } = useTranslations();

  return useCallback(() => {
    if (!countryData) return;

    const { phoneLength } = countryData;
    const isNaN = Number.isNaN(+numberValue);

    if (isNaN) {
      inputRef.current?.setInputError(tCommon('onlyNumbers'));
      return;
    }
    if (numberValue.length < phoneLength || numberValue.length > phoneLength) {
      inputRef.current?.setInputError(
        `${tCommon('numberLength')} ${phoneLength} ${tCommon('characters')}`,
      );
      return;
    }
    return true;
  }, [numberValue, countryData, inputRef, tCommon]);
}
