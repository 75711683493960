import { request } from '@src/api';
import {
  CheckConfirmationCodeArgs,
  CheckConfirmationCodeRequest,
  PasswordRecoveryResponse,
} from '@src/api/types';

export async function checkConfirmationCode({
  flow,
  phone,
  deviceUid: device_uid,
  code,
}: CheckConfirmationCodeArgs): Promise<void | PasswordRecoveryResponse> {
  if (flow === 'registration') {
    await request<never, CheckConfirmationCodeRequest>({
      url: 'c2c/user/confirmation/code/send',
      method: 'POST',
      data: { phone, device_uid, code },
    });
  }

  if (flow === 'passwordRecovery') {
    const { data } = await request<
      PasswordRecoveryResponse,
      CheckConfirmationCodeRequest
    >({
      url: 'c2c/user/authentication/',
      method: 'POST',
      data: { phone, device_uid, code },
    });

    return data;
  }
}
