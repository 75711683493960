import styled from 'styled-components';

import {
  animateOpacity,
  applyAnimation,
  slideBottom,
  slideLeft,
} from '@src/theme/animation';
import { AnimatedOpacity } from '@src/theme/commonTypes';

export const Title = styled.h1`
  font-family: ${({ theme }): string => theme.typography.fontFamily.secondary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ${({ theme }): string => theme.spacing(3)};
  ${applyAnimation(slideBottom)};
`;

export const FadingContainer = styled.div<AnimatedOpacity>`
  width: 100%;
  height: 100%;
  ${animateOpacity('fade')};
`;

export const FlowStepWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  ${applyAnimation(slideLeft)}
`;

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing(2)};
`;
