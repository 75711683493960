import Assembly14 from '@src/assets/images/assembly-two-cells/assembly-14.png';

import { AssemblyItemType } from '../type';

export default function useSIMCardLocation(): AssemblyItemType[] {
  return [
    {
      id: 1,
      image: Assembly14,
    },
  ];
}
