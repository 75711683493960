import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ContextProps } from '../types';
import { DEFAULT_HEADER_SETTINGS } from './constants';
import { HeaderSettings, HeaderSettingsContextValue } from './types';

const HeaderSettingsContext = createContext<HeaderSettingsContextValue | null>(
  null,
);

export function HeaderSettingsContextProvider({
  children,
}: ContextProps): JSX.Element {
  const [headerSettings, setHeaderSettings] = useState<HeaderSettings>(
    DEFAULT_HEADER_SETTINGS,
  );

  const setDefaultHeaderSettings = useCallback(() => {
    setHeaderSettings(DEFAULT_HEADER_SETTINGS);
  }, []);

  const setupHeaderSettings = useCallback((settings: HeaderSettings) => {
    setHeaderSettings((prevSettings) => ({
      ...prevSettings,
      ...settings,
    }));
  }, []);

  const value: HeaderSettingsContextValue = useMemo(
    () => ({
      headerSettings,
      setupHeaderSettings,
      setDefaultHeaderSettings,
    }),
    [headerSettings, setupHeaderSettings, setDefaultHeaderSettings],
  );

  return (
    <HeaderSettingsContext.Provider value={value}>
      {children}
    </HeaderSettingsContext.Provider>
  );
}

export function useHeaderSettingsContext(): HeaderSettingsContextValue {
  const value = useContext(HeaderSettingsContext);

  if (value) return value;

  throw new Error(
    'Please, use useHeaderSettingsContext inside HeaderSettingsContextProvider',
  );
}
