import { useEffect } from 'react';

import Button from '@src/components/Button/Button';
import { useDeviceInfoContext } from '@src/contexts/DeviceInfoContext';
import { useHeaderSettingsContext } from '@src/contexts/HeaderSettingsContext';
import { useSmoothlyRoutingContext } from '@src/contexts/SmoothlyRoutingContext';
import { useTranslations } from '@src/contexts/TranslationContext';
import { Loader } from '@src/theme/commonElements';
import { Path } from '@src/types/routes';

import {
  ButtonsWrapper,
  ContentContainer,
  Description,
  Image,
  OmniContainer,
  Title,
} from './styled';

export default function Home(): JSX.Element {
  const { deviceInfo, deviceContent } = useDeviceInfoContext();
  const { setupHeaderSettings } = useHeaderSettingsContext();
  const { t, tCommon } = useTranslations('omniHomeLaunching');
  const { startSmoothlyRouting } = useSmoothlyRoutingContext();

  const isLoading = !deviceInfo;

  useEffect(() => {
    setupHeaderSettings({
      isVisibleBackButton: false,
      isActiveRightButton: false,
    });
  }, [tCommon, setupHeaderSettings, startSmoothlyRouting]);

  function onUserGuideClick() {
    startSmoothlyRouting(Path.menu);
  }

  return (
    <>
      <Loader $isLoading={isLoading} />
      {!isLoading && (
        <>
          <OmniContainer>
            <Title>{t('congratulationsTitle')}</Title>
            <Description>{t('omniHomeReady')}</Description>
            <Image src={deviceContent.renderImage} />
          </OmniContainer>
          <ContentContainer>
            <ButtonsWrapper>
              <Button
                animation="slideTop"
                variant="contained"
                mode="dark"
                onClick={onUserGuideClick}
              >
                {t('start')}
              </Button>
            </ButtonsWrapper>
          </ContentContainer>
        </>
      )}
    </>
  );
}
