import { ThemeProvider } from 'styled-components';

import { AssemblageContextProvider } from '@src/contexts/AssemblageContext';
import { AuthContextProvider } from '@src/contexts/AuthContext';
import { DeviceInfoContextProvider } from '@src/contexts/DeviceInfoContext';
import { ErrorHandlerContextProvider } from '@src/contexts/ErrorHandlerContext';
import { FlowManagerContextProvider } from '@src/contexts/FlowManagerContext';
import { HeaderSettingsContextProvider } from '@src/contexts/HeaderSettingsContext';
import { NotificationsContextProvider } from '@src/contexts/NotificationsContext';
import { RedirectContextProvider } from '@src/contexts/RedirectContext';
import { TranslationContextProvider } from '@src/contexts/TranslationContext';
import { UserInfoContextProvider } from '@src/contexts/UserInfoContext';
import { defaultTheme } from '@src/theme/defaultTheme';
import { GlobalStyle } from '@src/theme/global';

import { useRoutes } from './hooks/useRoutes';

import '@src/assets/css/fonts.css';

import { RootContainer } from './styled';

export default function App(): JSX.Element {
  const routes = useRoutes();

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle />
      <AuthContextProvider>
        <TranslationContextProvider>
          <NotificationsContextProvider>
            <ErrorHandlerContextProvider>
              <AssemblageContextProvider>
                <FlowManagerContextProvider>
                  <UserInfoContextProvider>
                    <DeviceInfoContextProvider>
                      <RedirectContextProvider>
                        <HeaderSettingsContextProvider>
                          <RootContainer>{routes}</RootContainer>
                        </HeaderSettingsContextProvider>
                      </RedirectContextProvider>
                    </DeviceInfoContextProvider>
                  </UserInfoContextProvider>
                </FlowManagerContextProvider>
              </AssemblageContextProvider>
            </ErrorHandlerContextProvider>
          </NotificationsContextProvider>
        </TranslationContextProvider>
      </AuthContextProvider>
    </ThemeProvider>
  );
}
