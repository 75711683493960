import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDelayNavigation } from '@src/hooks/useDelayNavigation';
import { ColorMode } from '@src/types/common';
import { Path } from '@src/types/routes';

export function useSmoothlyChangeBg() {
  const [coverColor, setCoverColor] = useState<ColorMode>('primary');
  const [mainBgColor, setMainBgColor] = useState<ColorMode>('primary');
  const { pathname } = useLocation();

  const [isStarted, navigateWithDelay] = useDelayNavigation();

  const getBgColor = useCallback((path: string) => {
    if (path === Path.howToUse) {
      return 'dark';
    }
    if (
      path === Path.authorization ||
      path?.includes(Path.registration) ||
      path?.includes(Path.passwordRecovery) ||
      path?.includes(Path.changePassword)
    ) {
      return 'light';
    }
    return 'primary';
  }, []);

  const startSmoothlyRouting = useCallback(
    (path: string) => {
      const bgColor = getBgColor(path);

      setCoverColor(bgColor);
      navigateWithDelay(path);
    },
    [getBgColor, navigateWithDelay],
  );

  useEffect(() => {
    const bgColor = getBgColor(pathname);
    setMainBgColor(bgColor);
  }, [pathname, getBgColor]);

  return {
    isStarted,
    coverColor,
    mainBgColor,
    startSmoothlyRouting,
  };
}
