import styled from 'styled-components';

import LockIcon from '@src/assets/icons/lock.svg';
import { applyAnimation, slideLeft, slideTop } from '@src/theme/animation';

export const Lock = styled(LockIcon)`
  path {
    stroke: ${({ theme }): string => theme.palette.text.primary};
  }
`;

export const MiddleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const AuthFields = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${applyAnimation(slideLeft)};
`;

export const ForgotPasswordWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: ${({ theme }): string => theme.spacing()};
  ${applyAnimation(slideTop)};
`;

export const ForgotPasswordTitle = styled.span`
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  color: ${({ theme }): string => theme.palette.text.primary};
`;
