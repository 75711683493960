import { ReactNode } from 'react';

export enum ERegistrationStep {
  confirmationCode = 'confirmation-code',
  personalData = 'personal-data',
  address = 'address',
  password = 'password',
}

export type RegistrationSteps = {
  [K in ERegistrationStep]: ReactNode;
};
