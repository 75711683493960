import Assembly1 from '@src/assets/images/assembly-two-cells/assembly-1.png';
import Assembly2 from '@src/assets/images/assembly-two-cells/assembly-2.png';
import Assembly3 from '@src/assets/images/assembly-two-cells/assembly-3.png';
import Assembly4 from '@src/assets/images/assembly-two-cells/assembly-4.png';
import { useTranslations } from '@src/contexts/TranslationContext';

import { AssemblyItemType } from '../type';

export default function useGeneralAssembly(): AssemblyItemType[] {
  const { t } = useTranslations('assembly');

  return [
    {
      id: 1,
      text: t('generalAssemblyTitle1'),
      image: Assembly1,
    },
    {
      id: 2,
      text: t('generalAssemblyTitle2'),
      image: Assembly2,
    },
    {
      id: 3,
      text: t('generalAssemblyTitle3'),
      image: Assembly3,
    },
    {
      id: 4,
      text: t('generalAssemblyTitle4'),
      subList: [
        t('generalAssemblySubTitle1'),
        t('generalAssemblySubTitle2'),
        t('generalAssemblySubTitle3'),
      ],
      image: Assembly4,
    },
  ];
}
