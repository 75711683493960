import { useTranslations } from '@src/contexts/TranslationContext';

import { AssemblyInstruction } from '../type';
import useEmergency from './useEmergency';
import useFloorAnchoring from './useFloorAnchoring';
import useGeneralAssembly from './useGeneralAssembly';
import useInstallation from './useInstallation';
import useService from './useService';
import useSIMCardLocation from './useSIMCardLocation';

export default function useAssemblyInstructionsTwoCells(): AssemblyInstruction[] {
  const { t } = useTranslations('assembly');

  const generalAssembly = useGeneralAssembly();
  const installation = useInstallation();
  const emergency = useEmergency();
  const floorAnchoring = useFloorAnchoring();
  const service = useService();
  const simCardLocation = useSIMCardLocation();

  return [
    {
      id: 1,
      sectionTitle: t('generalAssemblySectionTitle'),
      list: generalAssembly,
    },
    {
      id: 2,
      sectionTitle: t('installationSectionTitle'),
      list: installation,
    },
    {
      id: 3,
      sectionTitle: t('emergencySectionTitle'),
      sectionDescription: t('emergencySectionDescription'),
      list: emergency,
    },
    {
      id: 4,
      sectionTitle: t('floorAnchoringSectionTitle'),
      list: floorAnchoring,
    },
    {
      id: 5,
      sectionTitle: t('serviceSectionTitle'),
      sectionDescription: t('serviceSectionDescription'),
      list: service,
    },
    {
      id: 6,
      sectionTitle: t('simCardLocationTitle'),
      sectionDescription: t('simCardLocationDescription'),
      list: simCardLocation,
    },
  ];
}
