import { InputId } from './types';

export const inputId: InputId = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  house: 'house',
  street: 'street',
  city: 'city',
};
