import { useCallback } from 'react';

import { UserInfoResponse } from '@src/api/types';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';
import { ActionType } from '@src/contexts/UserInfoContext/types';

export function useRecordUserInfoResponse() {
  const { dispatch } = useUserInfoContext();

  return useCallback(
    (userInfo: UserInfoResponse) => {
      const physicalAddress = userInfo.addresses.find(
        (address) => address.type === 'physical',
      ) as UserInfoResponse['addresses'][0];

      dispatch({
        type: ActionType.setAll,
        payload: {
          firstName: userInfo.contact.name.split(' ')[0],
          lastName: userInfo.contact.name.split(' ')[1],
          email: userInfo.contact.email,
          phone: userInfo.contact.phone,
          country: userInfo.country_code,
          countryName: physicalAddress.region,
          city: physicalAddress.city,
          street: physicalAddress.street,
          house: physicalAddress.house,
          addressDescription: userInfo.description,
        },
      });
    },
    [dispatch],
  );
}
