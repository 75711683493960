import { ReactNode } from 'react';

export enum ERecoveryStep {
  phoneNumber = 'phone-number',
  confirmationCode = 'confirmation-code',
  createPassword = 'create-password',
}

export type RecoverySteps = {
  [K in ERecoveryStep]: ReactNode;
};
