import { useRoutes as useBaseRoutes } from 'react-router-dom';

import MainContentWrapper from '@src/containers/MainContentWrapper/MainContentWrapper';
import Authorization from '@src/pages/Authorization';
import ChangePassword from '@src/pages/ChangePassword/ChangePassword';
import HowToUse from '@src/pages/HowToUse';
import Instruction from '@src/pages/Instruction';
import Main from '@src/pages/Main/Main';
import Menu from '@src/pages/Menu';
import PasswordRecovery from '@src/pages/PasswordRecovery';
import Registration from '@src/pages/Registration';
import RegistrationStep from '@src/pages/Registration/components/RegistrationStep';
import Home from '@src/pages/User/Home';
import UserInfo from '@src/pages/User/UserInfo';
import { Path, UserPath } from '@src/types/routes';

import PrivateRoute from '../components/Routes';

export function useRoutes() {
  return useBaseRoutes([
    {
      path: '/',
      element: <MainContentWrapper />,
      children: [
        {
          path: '/',
          element: <Main />,
        },
        {
          path: Path.authorization,
          element: <Authorization />,
        },
        {
          path: Path.instruction,
          element: <Instruction />,
        },
        {
          path: Path.registration,
          children: [
            {
              index: true,
              element: <Registration />,
            },
            {
              path: ':registrationStep',
              element: <RegistrationStep />,
            },
          ],
        },
        {
          path: `${Path.passwordRecovery}/:recoveryStep`,
          element: <PasswordRecovery />,
        },
        {
          path: Path.menu,
          element: <Menu />,
        },
        {
          path: Path.howToUse,
          element: <HowToUse />,
        },
        {
          path: Path.user,
          children: [
            {
              index: true,
              element: <PrivateRoute component={<Home />} />,
            },
            {
              path: UserPath.info,
              element: <PrivateRoute component={<UserInfo />} />,
            },
          ],
        },
        {
          path: Path.changePassword,
          element: <ChangePassword />,
        },
      ],
    },
  ]);
}
