import {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { ContextProps } from '../types';
import { languages } from './constants';
import {
  Key,
  Lang,
  ProjectTranslations,
  TranslationContextValue,
  Translations,
  TranslationTools,
} from './types';

const en = require('../../translations/en.json');
const uk = require('../../translations/uk.json');

const STORAGE_KEY = 'LANGUAGE';

const TranslationContext = createContext<TranslationContextValue | null>(null);

export function TranslationContextProvider({ children }: ContextProps) {
  const [currentLang, setCurrentLang] = useState<Lang>('en');
  const [isRTL, setIsRTL] = useState<boolean>(false);
  const [translations, setTranslates] = useState<Translations>();

  const setTranslatesHandler = useCallback(async (lang: Lang) => {
    setCurrentLang(lang);

    if (lang === 'uk') setTranslates(uk);
    if (lang === 'en') setTranslates(en);

    const currentLangData = languages[lang];

    if (currentLangData.direction === 'rtl') {
      setIsRTL(true);
    } else {
      setIsRTL(false);
    }

    document.body.dir = currentLangData.direction || 'ltr';
    document.documentElement.lang = currentLangData.htmlCode;
    document.head.lang = currentLangData.htmlCode;
  }, []);

  const selectLang = useCallback(
    async (lang: Lang) => {
      sessionStorage.setItem(STORAGE_KEY, lang);
      setTranslatesHandler(lang);
    },
    [setTranslatesHandler],
  );

  function getLanguageCode(code: string): Lang {
    switch (code) {
      case 'uk':
        return 'uk';
      default:
        return 'en';
    }
  }

  const setAppLang = useCallback(async () => {
    const languageCode = getLanguageCode(navigator.language.split('-')[0]);

    const savedLang = sessionStorage.getItem(STORAGE_KEY) as Lang | null;

    setTranslatesHandler(savedLang || languageCode);
  }, [setTranslatesHandler]);

  useLayoutEffect(() => {
    setAppLang();
  }, [setAppLang]);

  const value: TranslationContextValue = useMemo(
    () => ({
      isRTL,
      currentLang,
      translations,
      selectLang,
    }),
    [isRTL, currentLang, translations, selectLang],
  );

  return (
    <TranslationContext.Provider value={value}>
      {!!translations && children}
    </TranslationContext.Provider>
  );
}

export function useTranslations<K extends keyof ProjectTranslations>(
  screen?: K,
): TranslationTools<K> {
  const value = useContext(TranslationContext);

  if (!value?.translations) {
    throw new Error(
      'Please use useTranslations inside TranslationContextProvider',
    );
  }

  const t = useCallback(
    (key: Key<K>): string => {
      return screen && value.translations
        ? (value.translations[screen][key] as string)
        : '';
    },
    [screen, value.translations],
  );

  const tCommon = useCallback(
    (key: keyof Translations['common']): string => {
      return value.translations
        ? (value.translations.common[key] as string)
        : '';
    },
    [value.translations],
  );

  const tErrors = useCallback(
    (key: keyof Translations['errors']): string => {
      return value.translations
        ? (value.translations.errors[key] as string)
        : '';
    },
    [value.translations],
  );

  return {
    ...value,
    t,
    tCommon,
    tErrors,
  };
}
