import { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { DEVICE_UID } from '@src/constants/routing';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';
import { ActionType } from '@src/contexts/UserInfoContext/types';

export function useSaveQuery() {
  const savedUid = useRef<string | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { pathname } = useLocation();

  const { dispatch } = useUserInfoContext();

  useEffect(() => {
    const deviceUid = searchParams.get(DEVICE_UID);

    if (deviceUid) {
      dispatch({ type: ActionType.setDeviceUid, payload: deviceUid });
      savedUid.current = deviceUid;
    }
  }, [searchParams, dispatch]);

  useEffect(() => {
    const uid = savedUid.current;

    if (uid) {
      setSearchParams({ [DEVICE_UID]: uid }, { replace: true });
    }
  }, [pathname, setSearchParams]);
}
