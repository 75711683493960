import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getCurrentData } from '@src/api/utils/getCurrentData';
import { REDIRECT_TO } from '@src/constants/routing';
import { useErrorHandlerContext } from '@src/contexts/ErrorHandlerContext';

import { useAuthContext } from '../AuthContext';
import { ContextProps } from '../types';
import { useUserInfoContext } from '../UserInfoContext';
import { RedirectContextValue } from './types';

const RedirectContext = createContext<RedirectContextValue | null>(null);

const { URL_CODE_FOR_COURIER } = process.env;

export function RedirectContextProvider({
  children,
}: ContextProps): JSX.Element {
  const [isRedirect, setIsRedirect] = useState(false);

  const [searchParams] = useSearchParams();

  const redirectValue = searchParams.get(REDIRECT_TO);

  const { token } = useAuthContext();
  const {
    userInfo: { deviceUid },
  } = useUserInfoContext();
  const { handleError } = useErrorHandlerContext();

  useEffect(() => {
    const savedRedirectValue = sessionStorage.getItem(REDIRECT_TO);

    if (savedRedirectValue) {
      setIsRedirect(true);
    }
  }, []);

  useEffect(() => {
    if (redirectValue) {
      sessionStorage.setItem(REDIRECT_TO, redirectValue);
      setIsRedirect(true);
    }
  }, [redirectValue]);

  useEffect(() => {
    if (token && deviceUid && isRedirect) {
      sessionStorage.removeItem(REDIRECT_TO);

      getCurrentData(token)
        .then(({ uid: ownerUid }) => {
          setTimeout(() => {
            window
              .open(
                `${URL_CODE_FOR_COURIER}/?owner=${ownerUid}&device_uid=${deviceUid}`,
                '_self',
              )
              ?.focus();
          });
        })
        .catch((error) => {
          handleError(error.message);
        });
    }
  }, [token, deviceUid, isRedirect, handleError]);

  const value = useMemo(
    () => ({
      isRedirect,
    }),
    [isRedirect],
  );

  return (
    <RedirectContext.Provider value={value}>
      {children}
    </RedirectContext.Provider>
  );
}

export function useRedirectContext(): RedirectContextValue {
  const value = useContext(RedirectContext);

  if (!value) {
    throw new Error(
      'useRedirectContext must be used within RedirectContextProvider',
    );
  }

  return value;
}
