export enum StorageKey {
  Token = 'token',
  BearerToken = 'bearerToken',
}

export interface AuthContextValue {
  token: string;
  bearerToken: string;
  saveToken: (toke: string) => void;
  saveBearerToken: (toke: string) => void;
}
