import { ChildProps } from '../../types';

export enum ConfirmationCodeFlow {
  registration = 'registration',
  passwordRecovery = 'passwordRecovery',
}

export interface ConfirmationCodeProps extends ChildProps {
  flow: ConfirmationCodeFlow;
}

export interface TextWrapperProps {
  $isActive: boolean;
}
