import styled, { css, DefaultTheme, keyframes } from 'styled-components';

import {
  ContentContainer,
  OmniContainer as BaseOmniContainer,
  OmniDescription as BaseOmniDescription,
  OmniTitle as BaseOmniTitle,
} from '../../theme/commonElements';
import { ImageContainerProps } from './types';

const fadeIn = keyframes`
  0% {
    top: 50%;
  }
  15% {
    top: 50%;
  }
  50% {
    top: 50%;
  }
  60% {
    top: 50%;
  }
  100% {
    top: 10%;
  }
`;

const visibleImage = keyframes`
  0% {
    background-color: black;
  }
  65% {
    background-color: black;
  }
  100% {
    background-color: transparent;
  }
`;

const descriptionAnimation = keyframes`
  0% {
    opacity: 0;
    bottom: 70%;
  }
  80% {
    opacity: 0;
    bottom: 70%;
  }
  100% {
    opacity: 1;
    bottom: 100%;
  }
`;

const buttonFadeIn = keyframes`
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

function applyTitleAnimation({ theme }: { theme: DefaultTheme }) {
  const titleBefore = css`
    color: ${theme.palette.text.primary};
    text-shadow: -1px 1px 0 ${theme.palette.grey[0]},
      1px 1px 0 ${theme.palette.grey[0]}, 1px -1px 0 ${theme.palette.grey[0]},
      -1px -1px 0 ${theme.palette.grey[0]};
  `;
  const titleAfter = css`
    color: ${theme.palette.text.special};
    text-shadow: none;
  `;

  const titleAnimation = keyframes`
    0% {
      ${titleBefore};
    }
    65% {
      ${titleBefore};
    }
    100% {
      ${titleAfter};
    }
  `;

  return css`
    ${titleAfter};
    animation: 4s ease-in-out ${titleAnimation};
  `;
}

export const OmniTitle = styled(BaseOmniTitle)`
  color: ${({ theme }): string => theme.palette.primary};
`;

export const OmniContainer = styled(BaseOmniContainer)``;

export const OmniDescription = styled(BaseOmniDescription)`
  position: absolute;
  bottom: 100%;
  color: ${({ theme }): string => theme.palette.text.secondary};
`;

export const AnimatedButton = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(2)};
  opacity: 1;
  transform: translateY(0);
`;

function applyAnimatedElements({
  $isAnimated,
  $isLoading,
  theme,
}: ImageContainerProps) {
  if ($isLoading) {
    const fadeLoading = keyframes`
      0% {
        top: 55%;
        opacity: 0;
      }
      15% {
        top: 55%;
        opacity: 0;
      }
      50% {
        top: 50%;
        opacity: 1;
      }
      100% {
        top: 50%;
        opacity: 1;
      }
    `;
    return css`
      background-color: black;

      ${OmniTitle} {
        color: ${theme.palette.text.primary};
        text-shadow: -1px 1px 0 ${theme.palette.grey[0]},
          1px 1px 0 ${theme.palette.grey[0]},
          1px -1px 0 ${theme.palette.grey[0]},
          -1px -1px 0 ${theme.palette.grey[0]};
      }
      ${OmniContainer} {
        top: 50%;
        animation: 1s ease-in-out ${fadeLoading};
      }
      ${OmniDescription} {
        opacity: 0;
      }
      ${AnimatedButton} {
        opacity: 0;
      }
    `;
  }

  return $isAnimated
    ? css`
        animation: 4s linear ${visibleImage};
        ${OmniTitle} {
          ${applyTitleAnimation};
        }
        ${OmniContainer} {
          animation: 4s ease-in-out ${fadeIn};
        }
        ${OmniDescription} {
          animation: 5s ease-in-out ${descriptionAnimation};
        }
        ${AnimatedButton} {
          animation: 5s ease-in-out ${buttonFadeIn};
        }
      `
    : '';
}

export const ImageContainer = styled(ContentContainer)<ImageContainerProps>`
  background-size: cover;
  background-image: url(${({ $image }) => $image});
  background-color: transparent;
  background-blend-mode: darken;
  ${applyAnimatedElements};
`;
