import { useCallback, useRef } from 'react';

import Input from '@src/components/Input';
import { InputRef } from '@src/components/Input/types';
import TextArea from '@src/components/TextArea';
import { TextAreaRef } from '@src/components/TextArea/types';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';
import { ActionType } from '@src/contexts/UserInfoContext/types';
import { useDelayNavigation } from '@src/hooks/useDelayNavigation';
import { ERegistrationStep } from '@src/pages/Registration/types';
import { Path } from '@src/types/routes';

import { useSimpleFieldsReg } from '../../hooks/useSimleFieldsReg';

import { InputWrapper } from '../../styled';
import { FlowStepWrapper } from '@src/components/FlowManager/styled';

export default function Address(): JSX.Element {
  const [isStarted, navigateWithDelay] = useDelayNavigation();
  const { userInfo } = useUserInfoContext();
  const { tCommon } = useTranslations();

  const cityRef = useRef<InputRef>(null);
  const streetRef = useRef<InputRef>(null);
  const houseRef = useRef<InputRef>(null);
  const descriptionRef = useRef<TextAreaRef>(null);

  const onNextHandler = useCallback(() => {
    if (
      !cityRef.current ||
      !streetRef.current ||
      !houseRef.current ||
      !descriptionRef.current
    ) {
      return;
    }
    const isEmptyCity = cityRef.current.isEmpty();
    const isEmptyStreet = streetRef.current.isEmpty();
    const isEmptyHouse = houseRef.current.isEmpty();
    const isEmptyDescription = descriptionRef.current.isEmpty();

    if (
      !isEmptyCity &&
      !isEmptyStreet &&
      !isEmptyHouse &&
      !isEmptyDescription
    ) {
      navigateWithDelay(`${Path.registration}/${ERegistrationStep.password}`);
    }
  }, [navigateWithDelay]);

  const onChangeHandler = useSimpleFieldsReg(isStarted, onNextHandler);

  return (
    <FlowStepWrapper>
      <InputWrapper>
        <Input
          label={tCommon('city')}
          value={userInfo.city}
          onChange={onChangeHandler(ActionType.setCity)}
          ref={cityRef}
          required
        />
        <Input
          label={tCommon('street')}
          value={userInfo.street}
          onChange={onChangeHandler(ActionType.setStreet)}
          ref={streetRef}
          required
        />
        <Input
          label={tCommon('house')}
          value={userInfo.house}
          onChange={onChangeHandler(ActionType.setHouse)}
          ref={houseRef}
          required
        />
        <TextArea
          placeholder={tCommon('addressDescriptionPlaceholder')}
          value={userInfo.addressDescription}
          onChange={onChangeHandler(ActionType.setAddressDescription)}
          ref={descriptionRef}
          required
        />
      </InputWrapper>
    </FlowStepWrapper>
  );
}
