import { styled } from 'styled-components';

import { applyAnimation, slideBottom } from '@src/theme/animation';
import { ContentContainer as BaseContainer } from '@src/theme/commonElements';

export const ContentContainer = styled(BaseContainer)`
  justify-content: flex-start;
`;

export const Title = styled.h1`
  width: 100%;
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
  margin-bottom: ${({ theme }): string => theme.spacing(3)};
  text-align: left;
  text-transform: uppercase;
  ${applyAnimation(slideBottom)};
`;

export const MenuList = styled.ul`
  width: 100%;
  list-style: none;
`;
