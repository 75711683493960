import { useEffect } from 'react';

import { useAuthContext } from '@src/contexts/AuthContext';
import { useErrorHandlerContext } from '@src/contexts/ErrorHandlerContext';
import { useHeaderSettingsContext } from '@src/contexts/HeaderSettingsContext';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';

import { getCurrentData } from '../User/Home/utils/getCurrentData';
import { MenuItems, URL_CODE_FOR_COURIER } from './constants';
import MenuItem from './MenuItem';
import { MenuItemProps } from './MenuItem/types';

import { ContentContainer, MenuList, Title } from './styled';

export default function Menu(): JSX.Element {
  const { token } = useAuthContext();
  const {
    userInfo: { deviceUid },
  } = useUserInfoContext();
  const { setupHeaderSettings } = useHeaderSettingsContext();
  const { tCommon } = useTranslations('menu');
  const { handleError } = useErrorHandlerContext();

  useEffect(() => {
    setupHeaderSettings({
      isVisibleBackButton: true,
      RightButtonTitle: '',
    });
  }, [setupHeaderSettings]);

  function onClickDraft() {
    getCurrentData(token)
      .then(({ uid }) => {
        if (!window) return;
        setTimeout(() => {
          window
            .open(
              `${URL_CODE_FOR_COURIER}/?owner=${uid}&device_uid=${deviceUid}`,
              '_self',
            )
            ?.focus();
        });
      })
      .catch((error) => {
        handleError(error.message);
      });
  }

  return (
    <ContentContainer>
      <Title>{tCommon('menu')}</Title>
      {MenuItems && (
        <MenuList>
          {MenuItems.map(({ id, ...props }: MenuItemProps) => (
            <MenuItem key={id} {...props} />
          ))}

          {token && (
            <MenuItem
              key="codeForCourier"
              title="codeForCourier"
              onClick={onClickDraft}
            />
          )}
        </MenuList>
      )}
    </ContentContainer>
  );
}
