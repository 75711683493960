import styled, { css } from 'styled-components';

import { TextWrapperProps } from './types';

const Text = css`
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  letter-spacing: ${({ theme }): string => theme.typography.letterSpacing.xxs};
`;

function applyTextVisibility({ $isActive }: TextWrapperProps) {
  return $isActive
    ? css`
        opacity: 1;
        pointer-events: all;
      `
    : css`
        opacity: 0;
        pointer-events: none;
      `;
}

export const TextWrapper = styled.div<TextWrapperProps>`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: ${({ theme }): string => theme.palette.text.primary};
  padding-bottom: ${({ theme }): string => theme.spacing(5)};
  ${Text}
  ${applyTextVisibility}
`;

export const ButtonStyled = styled.button`
  background-color: transparent;
  border: none;
  text-decoration-line: underline;
  color: #3a3aff;
  ${Text}
`;
