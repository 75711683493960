/* eslint-disable no-console */
import axios, { AxiosRequestConfig } from 'axios';

import { ErrorType } from '@src/contexts/ErrorHandlerContext/types';

import { API_CUBE_CORE, HEADERS } from './constants';
import { CommonResponse } from './types';

export async function request<T extends object | null, D = never>(
  requestData: AxiosRequestConfig<D>,
): Promise<CommonResponse<T>> {
  const axiosConfig = axios.create({
    baseURL: API_CUBE_CORE,
    headers: HEADERS,
  });

  try {
    const res = await axiosConfig.request<CommonResponse<T>>(requestData);

    return res.data;
  } catch (err: AnyType) {
    if (axios.isCancel(err)) {
      console.error('HTTP REQUEST CANCELED');
      throw new Error(ErrorType.aborted);
    }

    console.error('HTTP REQUEST ERROR');
    console.error(
      'STATUS:',
      err.response?.data?.status_code || err.response.status || 500,
    );

    if (err.response?.data) {
      console.error('REASON:', err.response.data.message || 'unknown');
      if (err.response.data.ids) {
        console.error(err.response.data.ids);
        if (err.response.data.ids === ErrorType.somethingWentWrongWith) {
          throw new Error(err.response.data.message);
        }
        throw new Error(err.response.data.ids);
      }
    }
    throw new Error(err);
  }
}
