import Assembly12 from '@src/assets/images/assembly-two-cells/assembly-12.png';
import { useTranslations } from '@src/contexts/TranslationContext';

import { AssemblyItemType } from '../type';

export default function useFloorAnchoring(): AssemblyItemType[] {
  const { t } = useTranslations('assembly');

  return [
    {
      id: 1,
      text: t('floorAnchoringTitle1'),
    },
    {
      id: 2,
      text: t('floorAnchoringTitle2'),
    },
    {
      id: 3,
      text: t('floorAnchoringTitle3'),
    },
    {
      id: 4,
      text: t('floorAnchoringTitle4'),
      image: Assembly12,
    },
  ];
}
