import Assembly13 from '@src/assets/images/assembly-two-cells/assembly-13.png';
import { useTranslations } from '@src/contexts/TranslationContext';

import { AssemblyItemType } from '../type';

export default function useService(): AssemblyItemType[] {
  const { t } = useTranslations('assembly');

  return [
    {
      id: 1,
      text: t('serviceTitle1'),
    },
    {
      id: 2,
      text: t('serviceTitle2'),
    },
    {
      id: 3,
      text: t('serviceTitle3'),
    },
    {
      id: 4,
      text: t('serviceTitle4'),
      image: Assembly13,
    },
  ];
}
