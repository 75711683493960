import { Step } from '@src/types/common';

export enum StorageKey {
  lastStep = 'lastStep',
  completedSteps = 'completedSteps',
}

export interface AssemblageContextValue {
  lastStep?: Step;
  completedSteps: Step[];
  isAllStepsCompleted: boolean;
  recordLastStep: (step: Step) => void;
  recordCompletedSteps: (steps: Step[]) => void;
}
