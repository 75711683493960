import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { recoverPassword } from '@src/api/utils/recoverPassword';
import { useAuthContext } from '@src/contexts/AuthContext';
import { StorageKey } from '@src/contexts/AuthContext/types';
import { useErrorHandlerContext } from '@src/contexts/ErrorHandlerContext';
import { useApplyCommonState } from '@src/contexts/FlowManagerContext/hooks/useApplyCommonState';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';
import { ActionType } from '@src/contexts/UserInfoContext/types';
import { useSignIn } from '@src/hooks/useSignIn';

export interface PasswordRef {
  checkPassword: () => string | undefined;
}

interface UseChangePasswordProps {
  ref: React.RefObject<PasswordRef>;
  redirectBack?: boolean;
  isLogin?: boolean;
}

export default function useChangePassword({
  ref,
  isLogin,
  redirectBack,
}: UseChangePasswordProps) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const { token, bearerToken } = useAuthContext();
  const { authUserHandler } = useSignIn();

  const {
    userInfo: { phone },
    dispatch,
  } = useUserInfoContext();
  const { handleError } = useErrorHandlerContext();

  const savePassword = useCallback(async () => {
    const password = ref.current?.checkPassword();
    if (!password) return;

    setIsLoading(true);

    try {
      await recoverPassword({
        token: bearerToken || token,
        password,
      });

      localStorage.removeItem(StorageKey.BearerToken);

      if (redirectBack) {
        navigate(-1);
      }

      if (isLogin) {
        authUserHandler({ number: phone, password });
      }

      dispatch({
        type: ActionType.setPassword,
        payload: password,
      });
    } catch (error: AnyType) {
      handleError(error.message);
    }

    setIsLoading(false);
  }, [
    ref,
    token,
    phone,
    isLogin,
    bearerToken,
    redirectBack,
    navigate,
    dispatch,
    authUserHandler,
    handleError,
  ]);

  useApplyCommonState({
    isLoading,
    buttonAction: savePassword,
  });
}
