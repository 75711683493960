import { Path, UserPath } from '@src/types/routes';

import { MenuItem } from './types';

export const { URL_CODE_FOR_COURIER } = process.env;

export const MenuItems: MenuItem[] = [
  {
    id: 1,
    title: 'personalInfo',
    url: `${Path.user}/${UserPath.info}`,
    privateLink: true,
  },
  {
    id: 2,
    title: 'support',
    url: 'https://omnicampus.atlassian.net/servicedesk/customer/portal/9',
    blank: true,
  },
  {
    id: 3,
    title: 'assemblyInstructions',
    url: Path.instruction,
  },
  {
    id: 4,
    title: 'howToUse',
    url: Path.howToUse,
  },
];
