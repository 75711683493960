import styled from 'styled-components';

import { applyAnimation, slideBottom, slideLeft } from '@src/theme/animation';
import {
  ContentContainer as BaseContentContainer,
  OmniContainer as BaseOmniContainer,
} from '@src/theme/commonElements';

export const ContentContainer = styled(BaseContentContainer)`
  justify-content: flex-end;
`;

export const OmniContainer = styled(BaseOmniContainer)`
  ${applyAnimation(slideBottom)}
`;

export const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing(2)};
`;

export const Button = styled.div`
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  border-bottom: 2px solid ${({ theme }): string => theme.palette.grey[20]};
  padding-bottom: ${({ theme }): string => theme.spacing(2)};
  &:last-child {
    animation-delay: 0.2s;
  }
  ${applyAnimation(slideLeft)}
`;

export const ButtonText = styled.p`
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.l};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  max-width: 390px;
`;

export const Title = styled.h1`
  font-family: ${({ theme }): string => theme.typography.fontFamily.secondary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: ${({ theme }): string => theme.spacing(1.5)};
  ${applyAnimation(slideBottom)};
`;

export const Description = styled.p`
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;
