import { RefObject, useEffect } from 'react';

interface UseOutsideClickProps {
  ref: RefObject<HTMLElement>;
  isOpen: boolean;
  cb: () => void;
}

export function useOutsideClick({
  ref,
  isOpen,
  cb,
}: UseOutsideClickProps): void {
  useEffect(() => {
    const el = ref?.current;

    if (!el || !isOpen) return;

    const clickHandler = ({ target }: MouseEvent): void => {
      if (target !== el && !el.contains(target as Node)) {
        cb();
      }
    };

    window.document.addEventListener('click', clickHandler);

    return () => window.document.removeEventListener('click', clickHandler);
  }, [cb, ref, isOpen]);
}
