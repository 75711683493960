import { ReactNode } from 'react';

export enum StorageKey {
  deviceUid = 'deviceUid',
  deviceInfo = 'deviceInfo',
}

export interface ConfigurationView {
  device: string;
  stacks: Stack[];
}

export enum StackName {
  oneCell = 'omnihome_1cell_v1.0',
  twoCellsWithLeg = 'omnihome_2cells_v1.0',
  twoCells = 'omnihome_2cells_v2.0',
}

interface Stack {
  stack: number;
  cells: Cell[];
  name: StackName;
  width: number;
  height: number;
  depth: number;
}

interface Cell {
  cell: number;
  screen: boolean;
  row: number;
  width: number;
  height: number;
  depth: number;
}

export interface DeviceContent {
  name: StackName;
  mainImage: string;
  renderImage: string;
  instructionImage: string;
  assembly: ReactNode;
}

export interface DeviceInfoContextValue {
  deviceUid: string;
  deviceInfo?: ConfigurationView;
  deviceContent: DeviceContent;
}
