import { FunctionComponent, useEffect } from 'react';

import { useAuthContext } from '@src/contexts/AuthContext';
import { useHeaderSettingsContext } from '@src/contexts/HeaderSettingsContext';
import { useSmoothlyRoutingContext } from '@src/contexts/SmoothlyRoutingContext';
import { Path } from '@src/types/routes';

import { PrivateRouteProps } from './types';

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  component: Children,
}) => {
  const { token } = useAuthContext();

  const { setupHeaderSettings } = useHeaderSettingsContext();
  const { startSmoothlyRouting } = useSmoothlyRoutingContext();

  useEffect(() => {
    if (token) return;

    setupHeaderSettings({
      isVisibleBackButton: false,
    });
  }, [token, setupHeaderSettings]);

  if (token) {
    return Children;
  }

  startSmoothlyRouting(Path.authorization);
};

export default PrivateRoute;
