import styled, { css } from 'styled-components';

import CloseSVG from '@src/assets/icons/close.svg';

import { NotificationProps } from './types';

function applyNotificationStyle({ $isActive }: NotificationProps) {
  return $isActive
    ? css`
        transform: translateX(0px);
      `
    : css`
        transform: translateX(297px);
      `;
}

function applyBackground({ $status }: NotificationProps): string {
  if ($status === 'success') return 'rgba(246, 255, 239, 0.83)';
  if ($status === 'error') return 'rgba(254, 243, 243, 0.83)';
  return 'rgba(255, 255, 255, 0.83)';
}

export const Notification = styled.div<NotificationProps>`
  position: fixed;
  right: ${({ theme }): string => theme.spacing()};
  top: ${({ theme }): string => theme.spacing(3)};
  width: 288px;
  min-height: 74px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 9999;

  background: ${applyBackground};
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);

  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  ${applyNotificationStyle};
`;

export const Message = styled.p`
  flex-grow: 1;
  padding: ${({ theme }): string => theme.spacing(3)};

  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.s};
  color: ${({ theme }): string => theme.palette.text.primary};
`;

export const CloseIcon = styled(CloseSVG)`
  min-width: 14px;
  min-height: 14px;
  align-self: flex-start;
  margin-right: ${({ theme }): string => theme.spacing(3)};
  margin-top: ${({ theme }): string => theme.spacing(4)};
`;
