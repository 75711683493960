import { useCallback, useState } from 'react';

import { updateUserInfo } from '@src/api/utils/updateUserInfo';
import { useAuthContext } from '@src/contexts/AuthContext';
import { useErrorHandlerContext } from '@src/contexts/ErrorHandlerContext';

import { LocalUserInfo } from '../types';
import { useRecordUserInfoResponse } from './useRecordUserInfo';

interface UseUpdateUserInfoReturn {
  isUpdating: boolean;
  updateUserInfoHandler: () => void;
}

export function useUpdateUserInfo(
  localUserInfo: LocalUserInfo,
  checkInputs: () => boolean | undefined,
): UseUpdateUserInfoReturn {
  const [isUpdating, setIsUpdating] = useState(false);
  const { token } = useAuthContext();
  const recordUserInfoResponse = useRecordUserInfoResponse();
  const { handleError } = useErrorHandlerContext();

  const updateUserInfoHandler = useCallback(() => {
    const areInputsValid = checkInputs();
    if (!areInputsValid) return;

    setIsUpdating(true);
    updateUserInfo({
      token,
      addressDescription: localUserInfo.addressDescription,
      contact: {
        firstName: localUserInfo.firstName,
        lastName: localUserInfo.lastName,
        email: localUserInfo.email,
        phone: localUserInfo.phone,
      },
      address: {
        countryName: localUserInfo.countryName,
        countryCode: localUserInfo.country,
        city: localUserInfo.city,
        street: localUserInfo.street,
        house: localUserInfo.house,
      },
    })
      .then((newUserInfo) => {
        recordUserInfoResponse(newUserInfo);
      })
      .catch((error) => handleError(error.message))
      .finally(() => setIsUpdating(false));
  }, [token, localUserInfo, checkInputs, handleError, recordUserInfoResponse]);

  return { isUpdating, updateUserInfoHandler };
}
