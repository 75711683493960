import { request } from '@src/api';

import { AuthUserRequest, LoginResponse, SignInData } from '../types';

export async function authUser({
  number,
  password,
}: SignInData): Promise<LoginResponse> {
  const { data } = await request<LoginResponse, AuthUserRequest>({
    url: 'login/',
    method: 'POST',
    data: {
      login: number,
      password,
    },
  });

  return data;
}
