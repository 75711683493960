import { request } from '@src/api';
import { CreateUserRequest, UserTokenResponse } from '@src/api/types';

interface CreateUserArgs {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  deviceUid: string;
  country: string;
}

export async function createUser({
  firstName,
  lastName,
  phone,
  email,
  password,
  deviceUid,
  country,
}: CreateUserArgs): Promise<UserTokenResponse> {
  const { data } = await request<UserTokenResponse, CreateUserRequest>({
    url: '/omnihome/company/',
    method: 'POST',
    data: {
      device_uid: deviceUid,
      name: `OH ${firstName} ${lastName}`,
      country,
      base_user: {
        first_name: firstName,
        last_name: lastName,
        phone,
        password,
        email: email || undefined,
      },
    },
  });

  return data;
}
