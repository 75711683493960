import styled, { css } from 'styled-components';

import EmailSVG from '@src/assets/icons/email.svg';

import { FramesWrapperProps } from './types';

function applyFramesStyle({ $isError }: FramesWrapperProps) {
  return $isError
    ? css`
        input {
          color: ${({ theme }): string => theme.palette.error[90]};
          border-color: ${({ theme }): string => theme.palette.error[90]};
        }
      `
    : css`
        input {
          box-shadow: none;
        }
      `;
}

export const AccessCodeWrapper = styled.div`
  width: 100%;
`;

export const Form = styled.form`
  position: relative;
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const FramesWrapper = styled.div<FramesWrapperProps>`
  height: 100%;
  display: flex;
  justify-content: center;
  gap: ${({ theme }): string => theme.spacing(2)};

  ${applyFramesStyle};
`;

export const SingleInput = styled.input`
  background: transparent;
  outline: none;
  text-align: center;

  display: flex;
  align-items: center;
  width: 42px;
  height: 60px;
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-family: ${({ theme }): string => theme.typography.fontFamily.secondary};
  border-radius: 2px;
  border: 1px solid ${({ theme }): string => theme.palette.text.primary};

  &::placeholder {
    color: ${({ theme }): string => theme.palette.text.primary};
  }
`;

export const CodeText = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }): string => theme.palette.text.primary};
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
`;

export const EmailIcon = styled(EmailSVG)`
  margin-right: ${({ theme }): string => theme.spacing(1)};
`;
