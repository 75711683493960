import {
  ChangeEvent,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useState,
} from 'react';

import {
  CODE_LENGTH,
  FIRST_INPUT_ID,
  LAST_INPUT_ID,
  MIN_LENGTH,
} from '../constants';
import { AccessCode, InputData } from '../types';

export function useInputActions(
  onChangeCode?: (code: string) => void,
): InputData {
  const [value, setValue] = useState<AccessCode>(['']);
  const disableContextMenu = useCallback((event: Event) => {
    event.preventDefault();
  }, []);

  function focusInputById(id: string): void {
    const input = document.getElementById(id) as HTMLInputElement;
    input.focus();
  }

  function checkPrevInput(id: string) {
    const input = document.getElementById(id) as HTMLInputElement;
    if (!input.value) {
      focusInputById(id);
    }
  }

  const onFocusHandler = ({ target }: FocusEvent<HTMLInputElement>) => {
    const targetId = target.id;

    if (target.value) {
      target.addEventListener('contextmenu', disableContextMenu);
      target.setSelectionRange(0, 1);
    } else {
      target.removeEventListener('contextmenu', disableContextMenu);
      if (targetId !== FIRST_INPUT_ID) {
        checkPrevInput(`${+targetId - 1}`);
      }
    }
  };

  function onChangeHandler({ target }: ChangeEvent<HTMLInputElement>): void {
    const typedValue = target.value;
    const inputId = target.id;
    const { length: valueLength } = typedValue;

    const isValidInputData =
      !valueLength ||
      (!Number.isNaN(+typedValue) &&
        (valueLength === CODE_LENGTH || valueLength === MIN_LENGTH));

    if (!isValidInputData) return;

    if (!valueLength && inputId !== FIRST_INPUT_ID) {
      checkPrevInput(`${+inputId - 1}`);
    }

    if (valueLength === CODE_LENGTH) {
      const splittedValue = typedValue.split('');
      setValue(splittedValue);
      onChangeCode?.(splittedValue.join(''));
      return;
    }

    setValue((state) => {
      const newState = [...state];
      newState[+inputId] = typedValue;
      onChangeCode?.(newState.join(''));
      return newState;
    });

    if (!target.value) {
      target.removeEventListener('contextmenu', disableContextMenu);
    }

    if (typedValue && inputId !== LAST_INPUT_ID) {
      focusInputById(`${+inputId + 1}`);
    }
  }

  function onkeydownHandler(event: KeyboardEvent<HTMLInputElement>): void {
    const target = event.target as HTMLInputElement;
    const id = +target.id;
    const typedValue = target.value;

    if (event.key === 'Backspace' && id && !typedValue) {
      focusInputById(`${id - 1}`);
    }
  }

  return {
    value,
    onFocusHandler,
    onChangeHandler,
    onkeydownHandler,
  };
}
