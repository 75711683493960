import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { Step } from '@src/types/common';

import { useAuthContext } from '../AuthContext';
import { ContextProps } from '../types';
import { NUMBER_OF_STEPS } from './constants';
import { AssemblageContextValue, StorageKey } from './types';

const AssemblageContext = createContext<AssemblageContextValue | null>(null);

export function AssemblageContextProvider({
  children,
}: ContextProps): JSX.Element {
  const [lastStep, setLastStep] = useState<Step>();
  const [completedSteps, setCompletedSteps] = useState<Step[]>([]);
  const { token } = useAuthContext();

  useEffect(() => {
    if (token) {
      setCompletedSteps((prev) => [...prev, Step.registration]);
    }
  }, [token]);

  const recordLastStep = useCallback((step: Step) => {
    setLastStep(step);
    sessionStorage.setItem(StorageKey.lastStep, step);
  }, []);

  const recordCompletedSteps = useCallback((alreadyCompletedSteps: Step[]) => {
    setCompletedSteps(alreadyCompletedSteps);
    sessionStorage.setItem(
      StorageKey.completedSteps,
      JSON.stringify(alreadyCompletedSteps),
    );
  }, []);

  useEffect(() => {
    const lastStepFromStorage = sessionStorage.getItem(StorageKey.lastStep);
    const completedStepsFromStorage = sessionStorage.getItem(
      StorageKey.completedSteps,
    );

    if (lastStepFromStorage) {
      setLastStep(lastStepFromStorage as Step);
    }

    if (completedStepsFromStorage) {
      setCompletedSteps(JSON.parse(completedStepsFromStorage) as Step[]);
    }
  }, []);

  const value: AssemblageContextValue = useMemo(
    () => ({
      lastStep,
      completedSteps,
      isAllStepsCompleted: completedSteps.length === NUMBER_OF_STEPS,
      recordLastStep,
      recordCompletedSteps,
    }),
    [lastStep, completedSteps, recordLastStep, recordCompletedSteps],
  );

  return (
    <AssemblageContext.Provider value={value}>
      {children}
    </AssemblageContext.Provider>
  );
}

export function useAssemblageContext(): AssemblageContextValue {
  const value = useContext(AssemblageContext);

  if (value) return value;

  throw new Error(
    'Please, use useAssemblageContext inside AssemblageContextProvider',
  );
}
