import { forwardRef, ReactNode, useImperativeHandle } from 'react';

import { useTranslations } from '@src/contexts/TranslationContext';

import { CODE_LENGTH } from './constants';
import { useInputActions } from './hooks/useInputActions';
import { AccessCodeProps, AccessCodeRef } from './types';

import {
  AccessCodeWrapper,
  CodeText,
  EmailIcon,
  Form,
  FramesWrapper,
  SingleInput,
} from './styled';

const AccessCode = forwardRef<AccessCodeRef, AccessCodeProps>(
  ({ isError, onChangeCode }, ref): JSX.Element => {
    const { tCommon } = useTranslations();
    const { value, onChangeHandler, onFocusHandler, onkeydownHandler } =
      useInputActions(onChangeCode);

    function getInputs(): ReactNode {
      const inputs: ReactNode[] = [];

      for (let i = 0; i < CODE_LENGTH; i++) {
        inputs.push(
          <SingleInput
            key={`input/${i}`}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            placeholder="-"
            value={value[i] || ''}
            id={String(i)}
            onFocus={onFocusHandler}
            onInput={onChangeHandler}
            onKeyDown={onkeydownHandler}
          />,
        );
      }

      return inputs;
    }

    useImperativeHandle(ref, () => {
      return {
        getAccessCode: () => value.join(''),
      };
    });

    return (
      <AccessCodeWrapper>
        <Form>
          <FramesWrapper $isError={isError}>{getInputs()}</FramesWrapper>
        </Form>
        <CodeText>
          <EmailIcon />
          {tCommon('confirmationCode')}
        </CodeText>
      </AccessCodeWrapper>
    );
  },
);

export default AccessCode;
