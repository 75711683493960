import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useTranslations } from '../TranslationContext';
import { ContextProps } from '../types';
import {
  COUNTDOWN,
  COUNTDOWN_DURATION,
  ONE_SECONDS_INTERVAL,
} from './constants';
import { ButtonState, FlowManagerContextValue } from './types';

const FlowManagerContext = createContext<FlowManagerContextValue | null>(null);

export function FlowManagerContextProvider({
  children,
}: ContextProps): JSX.Element {
  const { tCommon } = useTranslations();
  const [buttonState, setButtonState] = useState<ButtonState>({
    text: tCommon('next'),
  });
  const [time, setTime] = useState(30);
  const [isTimerStarted, setIsTimerStarted] = useState(false);
  const [isRouteChanging, setIsRouteChanging] = useState(false);

  const startCountdown = useCallback((duration = COUNTDOWN_DURATION) => {
    setTime(duration);
    setIsTimerStarted(true);
  }, []);

  useEffect(() => {
    const savedTime = sessionStorage.getItem(COUNTDOWN);
    if (savedTime) {
      startCountdown(Number(savedTime));
    }
  }, [startCountdown]);

  useEffect(() => {
    if (!isTimerStarted) return;

    let timeout: NodeJS.Timeout;

    const interval = setInterval(() => {
      setTime((prevTime) => {
        const nextTime = prevTime - 1;
        if (nextTime === 0) {
          clearInterval(interval);
          timeout = setTimeout(() => {
            setIsTimerStarted(false);
          }, ONE_SECONDS_INTERVAL);
          sessionStorage.removeItem(COUNTDOWN);
        } else {
          sessionStorage.setItem(COUNTDOWN, nextTime.toString());
        }
        return nextTime;
      });
    }, ONE_SECONDS_INTERVAL);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    };
  }, [isTimerStarted]);

  const value = useMemo(
    () => ({
      time,
      buttonState,
      isTimerStarted,
      isRouteChanging,
      startCountdown,
      setButtonState,
      setIsRouteChanging,
    }),
    [
      time,
      buttonState,
      isTimerStarted,
      isRouteChanging,
      startCountdown,
      setButtonState,
      setIsRouteChanging,
    ],
  );

  return (
    <FlowManagerContext.Provider value={value}>
      {children}
    </FlowManagerContext.Provider>
  );
}

export function useFlowManagerContext(): FlowManagerContextValue {
  const value = useContext(FlowManagerContext);

  if (!value) {
    throw new Error(
      'useFlowManagerContext must be used within FlowManagerContextProvider',
    );
  }

  return value;
}
