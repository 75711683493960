import {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { ContextProps } from '../types';
import { AuthContextValue, StorageKey } from './types';

const AuthContext = createContext<AuthContextValue | null>(null);

export function AuthContextProvider({ children }: ContextProps): JSX.Element {
  const [token, setToken] = useState('');
  const [bearerToken, setBearerToken] = useState('');

  const saveToken = useCallback((incomingToken: string) => {
    setToken(`Token ${incomingToken}`);
    localStorage.setItem(StorageKey.Token, `Token ${incomingToken}`);
  }, []);

  const saveBearerToken = useCallback((incomingToken: string) => {
    setBearerToken(`Bearer ${incomingToken}`);
    localStorage.setItem(StorageKey.BearerToken, `Bearer ${incomingToken}`);
  }, []);

  useLayoutEffect(() => {
    const savedToken =
      localStorage.getItem(StorageKey.Token) ||
      localStorage.getItem(StorageKey.BearerToken);
    if (savedToken) {
      setToken(savedToken);
    }
  }, []);

  const value: AuthContextValue = useMemo(
    () => ({ token, bearerToken, saveToken, saveBearerToken }),
    [token, bearerToken, saveToken, saveBearerToken],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuthContext(): AuthContextValue {
  const value = useContext(AuthContext);

  if (value) return value;

  throw new Error('Please, use useAuthContext inside AuthContextProvider');
}
