import { useCallback, useRef, useState } from 'react';

import { getConfirmationCode } from '@src/api/utils/getConfirmationCode';
import PhoneInput from '@src/components/PhoneInput/PhoneInput';
import {
  PhoneInputData,
  PhoneInputRef,
} from '@src/components/PhoneInput/types';
import { ROUTING_DELAY } from '@src/constants/routing';
import { useErrorHandlerContext } from '@src/contexts/ErrorHandlerContext';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useApplyCommonState } from '@src/contexts/FlowManagerContext/hooks/useApplyCommonState';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';
import { ActionType } from '@src/contexts/UserInfoContext/types';
import { useDelayNavigation } from '@src/hooks/useDelayNavigation';

import { ChildProps } from '../../types';

import { FlowStepWrapper } from '../../styled';

export default function PhoneNumber({ nextRoute }: ChildProps): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const { time, isTimerStarted, startCountdown } = useFlowManagerContext();
  const { userInfo, dispatch } = useUserInfoContext();
  const phoneInputRef = useRef<PhoneInputRef>(null);

  const [isStarted, navigateWithDelay] = useDelayNavigation();
  const { handleError } = useErrorHandlerContext();
  const { tCommon } = useTranslations();

  const getConfirmationCodeHandler = useCallback(() => {
    if (!phoneInputRef.current) return;
    const { isNumberCorrect } = phoneInputRef.current.getPhoneInfo();
    if (!isNumberCorrect) return;

    setIsLoading(true);
    getConfirmationCode({
      deviceUid: userInfo.deviceUid,
      phone: userInfo.phone,
    })
      .then(() => {
        setTimeout(() => {
          startCountdown();
        }, ROUTING_DELAY);
        navigateWithDelay(nextRoute);
      })
      .catch((error) => handleError(error.message))
      .finally(() => setIsLoading(false));
  }, [
    userInfo.deviceUid,
    userInfo.phone,
    nextRoute,
    handleError,
    navigateWithDelay,
    startCountdown,
  ]);

  useApplyCommonState({
    text: (isTimerStarted && time) || tCommon('next'),
    isLoading,
    isStarted,
    isActive: !isTimerStarted,
    buttonAction: getConfirmationCodeHandler,
  });

  function onChangeHandler({ country, fullNumber }: PhoneInputData): void {
    dispatch({
      type: ActionType.setPhone,
      payload: fullNumber,
    });
    dispatch({
      type: ActionType.setCountry,
      payload: {
        country: country.code,
        countryName: country.name,
      },
    });
  }

  return (
    <FlowStepWrapper>
      <PhoneInput
        ref={phoneInputRef}
        onChange={onChangeHandler}
        initialCountry={userInfo.country}
        initialNumber={userInfo.phone}
      />
    </FlowStepWrapper>
  );
}
