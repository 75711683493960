import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useState,
} from 'react';

import { useTranslations } from '@src/contexts/TranslationContext';
import {
  FieldContainer,
  InfoContainer,
  InfoText,
  Label,
} from '@src/theme/commonElements';

import { DEFAULT_ERROR } from './constants';
import { InputError, InputProps, InputRef } from './types';

import {
  Button,
  HidePasswordIcon,
  InputWrapper,
  PhoneCode,
  ShowPasswordIcon,
  TextInput,
} from './styled';

const Input = forwardRef(
  (
    {
      label,
      isPassword,
      infoMessage,
      phoneCode,
      minPasswordLength = 6,
      value,
      type = 'text',
      mode = 'dark',
      required,
      id: propId,
      ...inputProps
    }: InputProps,
    ref?: ForwardedRef<InputRef>,
  ): JSX.Element => {
    const [isOnFocus, setIsOnFocus] = useState(false);
    const [isShownPassword, setIsShownPassword] = useState(false);

    const [{ isError, errorMessage }, setError] =
      useState<InputError>(DEFAULT_ERROR);

    const id = useId();

    const elementId = propId || id;
    const isLabelShown = !isOnFocus && !value?.trim() && !phoneCode;
    const isShowInputExtraInfo = !!infoMessage || (isError && !!errorMessage);

    const { isRTL, tCommon, tErrors } = useTranslations();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isRtlInput = isRTL && !phoneCode;

    useEffect(() => {
      setError(DEFAULT_ERROR);
    }, [value, phoneCode]);

    const eyeIcon = (
      <Button
        type="button"
        onClick={() => setIsShownPassword((prevState) => !prevState)}
      >
        {isShownPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
      </Button>
    );

    useImperativeHandle(
      ref,
      () => {
        return {
          setInputError: (errorText: string) => {
            setError({
              isError: true,
              errorMessage: errorText,
            });
          },
          isEmpty: () => {
            const isEmpty = !value?.trim();
            if (required && isEmpty) {
              setError({
                isError: true,
                errorMessage: tCommon('cannotBeEmpty'),
              });
            }
            return !!required && isEmpty;
          },
          isPasswordValid: () => {
            const isPasswordValid =
              (value?.length || 0) >= (minPasswordLength || 0);
            const isPasswordHasLetterAndNumber =
              /[a-zA-Z!@#$%^&*)(_+?.><]/.test(value || '') &&
              /\d/.test(value || '');

            if (!isPasswordValid) {
              setError({
                isError: true,
                errorMessage: `${tCommon(
                  'passwordRequirement1',
                )} ${minPasswordLength} ${tCommon('passwordRequirement2')}`,
              });
            } else if (!isPasswordHasLetterAndNumber) {
              setError({
                isError: true,
                errorMessage: tErrors('passwordNotHaveLetterOrNumber'),
              });
            }

            return isPasswordValid && isPasswordHasLetterAndNumber;
          },
          isEmailValid: () => {
            const isEmpty = !value?.trim();
            const isEmailValid = /\S+@\S+\.\S{2,}$/.test(value || '');

            if (isEmpty) {
              if (required) {
                setError({
                  isError: true,
                  errorMessage: tCommon('cannotBeEmpty'),
                });
              }

              return !required;
            }

            if (!isEmailValid && value?.trim()) {
              setError({
                isError: true,
                errorMessage: tCommon('emailIsNotValid'),
              });
            }
            return isEmailValid;
          },
          isPhoneValid: () => {
            const isEmpty = !value?.trim();
            const isPhoneNumberValid = /^\+?\d+$/.test(value || '');

            if (isEmpty) {
              if (required) {
                setError({
                  isError: true,
                  errorMessage: tCommon('cannotBeEmpty'),
                });
              }

              return !required;
            }

            if (!isPhoneNumberValid) {
              setError({
                isError: true,
                errorMessage: tCommon('phoneIsNotValid'),
              });
            }
            return isPhoneNumberValid;
          },
        };
      },
      [required, value, minPasswordLength, tCommon, tErrors],
    );

    return (
      <FieldContainer $isError={isError} $mode={mode}>
        {label && (
          <Label htmlFor={elementId} $isLabelShown={isLabelShown}>
            {label} {required ? <sup>*</sup> : '(optional)'}
          </Label>
        )}
        <InputWrapper>
          {phoneCode && <PhoneCode>{phoneCode}</PhoneCode>}
          <TextInput
            id={elementId}
            onFocus={() => {
              setIsOnFocus(true);
            }}
            onBlur={() => {
              setIsOnFocus(false);
            }}
            value={value}
            type={isPassword && !isShownPassword ? 'password' : type}
            required={required}
            autoComplete="off"
            {...inputProps}
            $isPassword={isPassword}
          />
          {isPassword && eyeIcon}
        </InputWrapper>
        {isShowInputExtraInfo && (
          <InfoContainer>
            <InfoText>{errorMessage || infoMessage}</InfoText>
          </InfoContainer>
        )}
      </FieldContainer>
    );
  },
);

export default Input;
