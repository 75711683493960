import styled, { css } from 'styled-components';

import { applyAnimation, slideLeft, slideTop } from '@src/theme/animation';

const textStyle = css`
  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  letter-spacing: ${({ theme }): string => theme.typography.letterSpacing.xxs};
`;

const countElement = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }): string => theme.palette.background.special};

  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.s};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
  letter-spacing: ${({ theme }): string => theme.typography.letterSpacing.xs};
`;

export const Title = styled.h1`
  font-family: ${({ theme }): string => theme.typography.fontFamily.secondary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xl};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  color: ${({ theme }): string => theme.palette.text.primary};
  letter-spacing: ${({ theme }): string => theme.typography.letterSpacing.l};
  text-transform: uppercase;

  margin-bottom: ${({ theme }): string => theme.spacing(3)};
`;

export const Image = styled.img`
  display: block;
  max-width: 100%;
`;

export const Text = styled.div`
  ${textStyle};
  margin-bottom: ${({ theme }): string => theme.spacing(3.75)};
`;

export const List = styled.ol`
  margin-top: ${({ theme }): string => theme.spacing(3)};

  li {
    ${applyAnimation(slideTop)};

    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
    &:nth-child(5) {
      animation-delay: 0.8s;
    }
    &:nth-child(6) {
      animation-delay: 1s;
    }
  }
`;

export const ListItem = styled.li`
  ${textStyle};
  list-style-position: inside;
`;

export const InstructionWrapper = styled.div`
  background-color: ${({ theme }): string => theme.palette.background.white};
  padding: ${({ theme: { spacing } }): string =>
    `${spacing(4.5)} ${spacing(2.5)}`};
`;

export const InstructionItem = styled.div`
  &:not(:last-child) {
    margin-bottom: ${({ theme: { spacing } }): string => spacing(4.5)};
  }
`;

export const ComplectWrapper = styled.ol`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme: { spacing } }): string => spacing(1.5)};
  list-style: none;

  li {
    ${applyAnimation(slideLeft)};

    &:nth-child(2) {
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      animation-delay: 0.4s;
    }
    &:nth-child(4) {
      animation-delay: 0.6s;
    }
    &:nth-child(5) {
      animation-delay: 0.8s;
    }
    &:nth-child(6) {
      animation-delay: 1s;
    }
  }
`;

export const ComplectItem = styled.li`
  position: relative;
  width: 30%;

  &::before {
    content: attr(data-marker);
    ${countElement}
  }
`;

export const AssemblyWrapper = styled.div``;

export const AssemblyItem = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-bottom: ${({ theme }): string => theme.spacing(3)};
  }

  &::before {
    content: attr(data-marker);
    ${countElement}
  }

  & ${Text} {
    padding-left: ${({ theme }): string => theme.spacing(5)};
  }
`;
