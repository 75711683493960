import { useEffect, useRef, useState } from 'react';

import { COUNTDOWN_DURATION } from '@src/contexts/FlowManagerContext/constants';

import {
  CIRCLE_LENGTH,
  CIRCLE_POSITION,
  CIRCLE_RADIUS,
  CIRCLE_SIZE,
  STROKE_WIDTH,
} from './constants';
import { CircleDimensions, CountdownProps, Sizes } from './types';

import { CountdownContainer, CountdownText, SVGCircle } from './styled';

const Countdown = ({ time }: CountdownProps): JSX.Element => {
  const [circleDimensions, setCircleDimensions] = useState<CircleDimensions>({
    circleSize: CIRCLE_SIZE,
    circlePosition: CIRCLE_POSITION,
    circleRadius: CIRCLE_RADIUS,
    circleLength: CIRCLE_LENGTH,
    strokeWidth: STROKE_WIDTH,
    strokeDashoffset: 0,
  });

  const initTime = useRef(time);

  useEffect(() => {
    setCircleDimensions((prevDimensions) => {
      const strokeDashoffset =
        prevDimensions.circleLength -
        (prevDimensions.circleLength / initTime.current) * time;

      return {
        ...prevDimensions,
        strokeDashoffset,
      };
    });
  }, [time]);

  return (
    <CountdownContainer>
      <SVGCircle
        width={circleDimensions.circleSize}
        height={circleDimensions.circleSize}
      >
        <g origin={circleDimensions.circlePosition}>
          <circle
            cx={circleDimensions.circlePosition}
            cy={circleDimensions.circlePosition}
            r={circleDimensions.circleRadius}
            strokeWidth={Sizes.strokeM}
            strokeDasharray={circleDimensions.circleLength}
            strokeLinecap="round"
          />
          <circle
            cx={circleDimensions.circlePosition}
            cy={circleDimensions.circlePosition}
            r={circleDimensions.circleRadius}
            strokeWidth={Sizes.strokeS}
            strokeDasharray={circleDimensions.circleLength}
            strokeDashoffset={circleDimensions.strokeDashoffset}
            strokeLinecap="round"
          />
        </g>
      </SVGCircle>
      <CountdownText>
        {initTime.current ? time : COUNTDOWN_DURATION}
      </CountdownText>
    </CountdownContainer>
  );
};

export default Countdown;
