import Loader from '../Loader';
import { ButtonProps } from './types';

import { AnimatedWrapper, ButtonCommon, StyledA, StyledLink } from './styled';

export default function Button({
  to,
  href,
  children,
  variant,
  mode,
  animation,
  isActive = true,
  isLoading = false,
  onClick,
}: ButtonProps): JSX.Element {
  const isActiveBtn = !isLoading && isActive;

  function getButton(): JSX.Element {
    const button = (
      <AnimatedWrapper $animation={animation}>
        <ButtonCommon
          onClick={onClick}
          $isActive={isActiveBtn}
          $variant={variant}
          $mode={mode}
        >
          {isLoading ? (
            <Loader mode={mode === 'dark' ? 'primary' : 'dark'} />
          ) : (
            children
          )}
        </ButtonCommon>
      </AnimatedWrapper>
    );

    if (to) {
      return <StyledLink to={to}>{button}</StyledLink>;
    }
    if (href) {
      return <StyledA href={href}>{button}</StyledA>;
    }

    return button;
  }

  return getButton();
}
