import Button from '@src/components/Button';
import { useTranslations } from '@src/contexts/TranslationContext';

import { WithImageBgProps } from './types';

import {
  AnimatedButton,
  ImageContainer,
  OmniContainer,
  OmniDescription,
  OmniTitle,
} from './styled';

export default function WithImageBg({
  image,
  buttonTitle,
  secondButtonTitle,
  isAnimated = true,
  buttonAction,
  secondButtonAction,
  isLoading,
}: WithImageBgProps): JSX.Element {
  const { t } = useTranslations('omniHomeLaunching');

  return (
    <ImageContainer
      $image={image}
      $isAnimated={isAnimated}
      $isLoading={isLoading}
    >
      <OmniContainer>
        <OmniDescription>{t('description')}</OmniDescription>
        <OmniTitle>{t('title')}</OmniTitle>
      </OmniContainer>
      <AnimatedButton>
        <Button variant="contained" mode="primary" onClick={buttonAction}>
          {buttonTitle}
        </Button>
        {!!secondButtonTitle && (
          <Button variant="contained" mode="dark" onClick={secondButtonAction}>
            {secondButtonTitle}
          </Button>
        )}
      </AnimatedButton>
    </ImageContainer>
  );
}
