import Button from '@src/components/Button';
import { useAuthContext } from '@src/contexts/AuthContext';
import { useSmoothlyRoutingContext } from '@src/contexts/SmoothlyRoutingContext';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useSetDefaultHeader } from '@src/hooks/useSetDefaultHeader';
import { Path } from '@src/types/routes';

import { assemblyList } from './constants';

import {
  AssemblyItem,
  AssemblyWrapper,
  ButtonsWrapper,
  Description,
  FinalStep,
  Image,
  InstructionItem,
  InstructionWrapper,
  Text,
  TextWrapper,
  Title,
  TopWrapper,
} from './styled';

export default function HowToUse(): JSX.Element {
  const { token } = useAuthContext();
  const { t, tCommon } = useTranslations('omniHomeLaunching');
  const { startSmoothlyRouting } = useSmoothlyRoutingContext();
  useSetDefaultHeader();

  function getFinalStepValues() {
    return token
      ? {
          title: t('title'),
          description: t('description'),
          button: tCommon('mainPage'),
          action: () => startSmoothlyRouting(Path.user),
        }
      : {
          title: t('finalSetup'),
          description: t('finalSetupDescription'),
          button: tCommon('registration'),
          action: () => startSmoothlyRouting(Path.registration),
        };
  }

  return (
    <>
      <TopWrapper>
        <TextWrapper>
          <Title>{t('title')}</Title>
          <Description>{t('howToUse')}</Description>
        </TextWrapper>
      </TopWrapper>
      <InstructionWrapper>
        <InstructionItem>
          <Title>{t('assemblyInstructions')}</Title>
          <AssemblyWrapper>
            {assemblyList.map(({ id, text, image }) => {
              return (
                <AssemblyItem key={id} data-marker={id}>
                  <Text>{text}</Text>
                  <Image src={image} />
                </AssemblyItem>
              );
            })}
          </AssemblyWrapper>
        </InstructionItem>
      </InstructionWrapper>
      <FinalStep>
        <Title>{getFinalStepValues().title}</Title>
        <Description>{getFinalStepValues().description}</Description>
        <ButtonsWrapper>
          <Button
            variant="contained"
            mode="primary"
            onClick={getFinalStepValues().action}
          >
            {getFinalStepValues().button}
          </Button>
        </ButtonsWrapper>
      </FinalStep>
    </>
  );
}
