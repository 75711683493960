import { LazyLoadComponent } from 'react-lazy-load-image-component';

import useAssemblyInstructionsTwoCells from './hooks/useAssemblyInstructionsTwoCells';
import { AssemblyItemType } from './type';

import {
  AssemblyItem,
  AssemblyWrapper,
  Description,
  Image,
  InstructionItem,
  InstructionWrapper,
  SubList,
  SubListItem,
  SubPoints,
  SubPointsItem,
  Text,
  Title,
} from './styled';

export default function AssemblyTwoCells() {
  const assemblyTwoCells = useAssemblyInstructionsTwoCells();

  function instructionList(list?: AssemblyItemType[]) {
    if (!list) return;

    return (
      <AssemblyWrapper>
        {list.map(({ id: itemId, text, image, subList, subPoints }) => {
          return (
            <AssemblyItem key={itemId} data-marker={itemId}>
              <Text>{text}</Text>
              {subList?.length && (
                <SubList>
                  {subList.map((item: string) => (
                    <SubListItem key={item}>{item}</SubListItem>
                  ))}
                </SubList>
              )}
              {subPoints?.length && (
                <SubPoints>
                  {subPoints.map((item: string) => (
                    <SubPointsItem key={item}>{item}</SubPointsItem>
                  ))}
                </SubPoints>
              )}
              <LazyLoadComponent>
                <Image src={image} />
              </LazyLoadComponent>
            </AssemblyItem>
          );
        })}
      </AssemblyWrapper>
    );
  }

  return (
    <InstructionWrapper>
      {assemblyTwoCells.map(
        ({ id, sectionTitle, sectionDescription, list }) => {
          return (
            <InstructionItem key={id}>
              <Title>{sectionTitle}</Title>
              {sectionDescription && (
                <Description>{sectionDescription}</Description>
              )}
              {instructionList(list)}
            </InstructionItem>
          );
        },
      )}
    </InstructionWrapper>
  );
}
