import { useCallback } from 'react';
import axios from 'axios';

import { CountryCode } from '@src/components/PhoneInput/components/CountryPicker/types';

interface UserData {
  ip: string;
  city: string;
  region: string;
  country: CountryCode;
  loc: string;
}

type HookReturn = () => Promise<UserData | undefined>;

const { API_IP_INFO } = process.env;
const { API_IP_INFO_TOKEN } = process.env;

export function useUserDataFromIP(): HookReturn {
  return useCallback(async () => {
    try {
      const response = await axios.get<UserData>(
        `${API_IP_INFO}?token=${API_IP_INFO_TOKEN}`,
      );

      return response.data;
    } catch (err: AnyType) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);
}
