import { useRef } from 'react';

import Password from '@src/components/Password';
import { PasswordRef } from '@src/components/Password/types';
import useChangePassword from '@src/hooks/useChangePassword';

import { FlowStepWrapper } from '@src/components/FlowManager/styled';

export default function NewPasswordCreation(): JSX.Element {
  const passwordRef = useRef<PasswordRef>(null);

  useChangePassword({
    ref: passwordRef,
    isLogin: true,
  });

  return (
    <FlowStepWrapper>
      <Password ref={passwordRef} />
    </FlowStepWrapper>
  );
}
