import { request } from '@src/api';

import { GetConfirmationCodeProps } from '../types';

export async function getConfirmationCode({
  phone,
  deviceUid,
}: GetConfirmationCodeProps): Promise<void> {
  await request({
    url: `c2c/user/confirmation/code/send?phone=${phone}&device_uid=${deviceUid}`,
  });
}
