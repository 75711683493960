import styled from 'styled-components';

import ChevronSVG from '@src/assets/icons/chevron.svg';
import { applyAnimation, slideLeft } from '@src/theme/animation';

export const ChevronIcon = styled(ChevronSVG)`
  transform: rotate(180deg);
  margin-right: ${({ theme }) => theme.spacing(1.5)};
`;

export const Item = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.palette.secondary};

  ${applyAnimation(slideLeft)};

  &:nth-child(1) {
    animation-delay: 0.1s;
  }
  &:nth-child(2) {
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    animation-delay: 0.3s;
  }
  &:nth-child(4) {
    animation-delay: 0.4s;
  }
  &:nth-child(5) {
    animation-delay: 0.5s;
  }
`;

export const Link = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing(3)} 0`};
  text-decoration: none;

  color: ${({ theme }) => theme.palette.text.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.l};
  font-weight: ${({ theme }) => theme.typography.fontWeight[500]};
  line-height: 1.27;
`;
