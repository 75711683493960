import styled, { css, keyframes } from 'styled-components';

import { FieldContainerProps, LabelProps } from './commonTypes';

import {
  InputWrapper,
  PhoneCode,
  TextInput,
} from '@src/components/Input/styled';

export const textCommon = css`
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.m};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
`;

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  padding: ${({ theme: { spacing } }): string => `${spacing(5)} ${spacing(3)}`};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const TopWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

export const OmniTitle = styled.h1`
  font-family: ${({ theme }): string => theme.typography.fontFamily.secondary};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[700]};
  color: ${({ theme }): string => theme.palette.text.primary};
  font-size: 42px;
  letter-spacing: ${({ theme }) => theme.typography.letterSpacing.m};
`;

export const OmniContainer = styled.div`
  position: absolute;
  top: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const OmniDescription = styled.h2`
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  font-size: ${({ theme }): string => theme.typography.fontSize.m};
  color: ${({ theme }): string => theme.palette.text.primary};
`;

function applyPlaceholderVisibility({ $isLabelShown }: LabelProps) {
  return $isLabelShown
    ? css`
        font-size: ${({ theme }): string => theme.typography.fontSize.s};
        color: ${({ theme }): string => theme.palette.text.additional};
        transform: translateY(100%);
      `
    : css`
        font-size: ${({ theme }): string => theme.typography.fontSize.xxs};
        color: ${({ theme }): string => theme.palette.text.primary};
        transform: translateY(0%);
      `;
}

export const Label = styled.label<LabelProps>`
  position: relative;
  display: inline-block;
  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  ${applyPlaceholderVisibility};

  sup {
    position: absolute;
  }
`;

export const InfoContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 100%;
  margin-top: ${({ theme }): string => theme.spacing(0.5)};
  gap: ${({ theme }): string => theme.spacing(0.5)};
`;

export const InfoText = styled.span`
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xxs};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[400]};
  color: ${({ theme }): string => theme.palette.text.primary};
`;

function applyErrorStyles({ $isError }: FieldContainerProps) {
  return (
    $isError &&
    css`
      ${Label} {
        color: ${({ theme }): string => theme.palette.error[90]};
      }
      ${InfoText} {
        color: ${({ theme }): string => theme.palette.error[90]};
      }
      ${InputWrapper} {
        border-bottom-color: ${({ theme }): string => theme.palette.error[90]};
      }
    `
  );
}

export function applyModeStyles({ $mode }: FieldContainerProps) {
  return (
    $mode === 'primary' &&
    css`
      ${Label} {
        color: ${({ theme }): string => theme.palette.grey[5]};
      }
      ${InfoText} {
        color: ${({ theme }): string => theme.palette.grey[5]};
      }
      ${InputWrapper} {
        border-bottom-color: ${({ theme }): string => theme.palette.grey[5]};
      }
      ${TextInput} {
        color: ${({ theme }): string => theme.palette.grey[5]};
      }
      ${PhoneCode} {
        color: ${({ theme }): string => theme.palette.grey[5]};
      }
    `
  );
}

export const FieldContainer = styled.div<FieldContainerProps>`
  position: relative;
  width: 100%;
  margin-bottom: ${({ theme }): string => theme.spacing(3)};
  ${applyModeStyles};
  ${applyErrorStyles};
`;

const load = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.div<{ $isLoading: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-indent: -9999em;
  transform: translateZ(0);
  animation: ${load} 1.1s infinite linear;

  border-width: 10px;
  border-style: solid;
  border-color: #fcff2f6d;
  border-left-color: #ffee00ff;
  opacity: ${({ $isLoading }): number => ($isLoading ? 1 : 0)};
  transition-duration: 1s;
  pointer-events: none;

  &,
  &::after {
    border-radius: 50%;
    width: 150px;
    height: 150px;
  }
`;
