import { useEffect } from 'react';

import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useTranslations } from '@src/contexts/TranslationContext';

interface ApplyCommonStateProps {
  text?: string | number;
  isLoading?: boolean;
  isActive?: boolean;
  isStarted?: boolean;
  buttonAction: () => void;
}

export function useApplyCommonState({
  text,
  isLoading,
  isActive,
  isStarted = false,
  buttonAction,
}: ApplyCommonStateProps) {
  const { tCommon } = useTranslations();
  const { setButtonState, setIsRouteChanging } = useFlowManagerContext();

  useEffect(() => {
    setIsRouteChanging(isStarted);
    setButtonState({
      text: text || tCommon('next'),
      isLoading,
      isActive,
      buttonAction,
    });
  }, [
    text,
    isLoading,
    isStarted,
    isActive,
    buttonAction,
    setButtonState,
    tCommon,
    setIsRouteChanging,
  ]);
}
