import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import { DEVICE_UID, OWNER_PHONE } from '@src/constants/routing';

import { ContextProps } from '../types';
import { ActionType, UserInfoContextValue } from './types';
import { initialUserInfo, reducer, savedUserInfo } from './utils/reducer';

const UserInfoContext = createContext<UserInfoContextValue | null>(null);

export function UserInfoContextProvider({
  children,
}: ContextProps): JSX.Element {
  const [userInfo, dispatch] = useReducer(
    reducer,
    savedUserInfo || initialUserInfo,
  );

  const [searchParams] = useSearchParams();

  const ownerPhone = searchParams.get(OWNER_PHONE);
  const deviceUid = searchParams.get(DEVICE_UID);

  const value = useMemo(
    () => ({
      userInfo,
      dispatch,
    }),
    [userInfo, dispatch],
  );

  useEffect(() => {
    if (ownerPhone) {
      dispatch({ type: ActionType.setPhone, payload: ownerPhone });
    }
  }, [ownerPhone]);

  useEffect(() => {
    if (deviceUid) {
      dispatch({ type: ActionType.setDeviceUid, payload: deviceUid });
    }
  }, [deviceUid]);

  return (
    <UserInfoContext.Provider value={value}>
      {children}
    </UserInfoContext.Provider>
  );
}

export function useUserInfoContext(): UserInfoContextValue {
  const value = useContext(UserInfoContext);

  if (!value) {
    throw new Error(
      'useUserInfoContext must be used within UserInfoContextProvider',
    );
  }

  return value;
}
