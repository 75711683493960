import { useEffect, useRef } from 'react';

import FlowManager from '@src/components/FlowManager';
import Password from '@src/components/Password';
import { PasswordRef } from '@src/components/Password/types';
import { useHeaderSettingsContext } from '@src/contexts/HeaderSettingsContext';
import { useTranslations } from '@src/contexts/TranslationContext';
import useChangePassword from '@src/hooks/useChangePassword';

import { MiddleWrapper } from './styled';

export default function ChangePassword(): JSX.Element {
  const passwordRef = useRef<PasswordRef>(null);

  const { setupHeaderSettings } = useHeaderSettingsContext();
  const { tCommon } = useTranslations();

  useChangePassword({
    ref: passwordRef,
    redirectBack: true,
  });

  useEffect(() => {
    setupHeaderSettings({
      isVisibleBackButton: true,
      RightButtonTitle: '',
    });
  }, [setupHeaderSettings]);

  return (
    <FlowManager title={tCommon('changePassword')}>
      <MiddleWrapper>
        <Password ref={passwordRef} />
      </MiddleWrapper>
    </FlowManager>
  );
}
