export const COLORS = {
  yellow: {
    100: '#FBFF2F',
  },
  green: {
    30: '#68ec9e',
    40: '#5AFF9C',
    50: '#40fe11',
  },
  grey: {
    0: '#ffffff',
    5: '#F8F8F3',
    20: '#68686333',
    30: '#999999',
    50: '#686863',
    100: '#000000',
  },
  red: {
    70: '#FF3E3E',
    80: '#E72F2F',
    90: '#d42222',
  },
};
