import instructionImageOneCell from '@src/assets/images/assembly-one-cell/instruction.jpg';
import renderImageOneCell from '@src/assets/images/assembly-one-cell/render.jpg';
import instructionImageTwoCells from '@src/assets/images/assembly-two-cells/instruction.png';
import renderImageTwoCells from '@src/assets/images/assembly-two-cells/render.png';
import mainImageOneCell from '@src/assets/images/main_one_cell.jpeg';
import mainImageTwoCells from '@src/assets/images/main_two_cells.jpg';

import AssemblyOneCell from './components/AssemblyOneCell';
import AssemblyTwoCells from './components/AssemblyTwoCells';
import { DeviceContent, StackName } from './types';

export const DELAY_FOR_ANIMATION = 600;

export const DEFAULT_DEVICE_CONTENT: DeviceContent = {
  name: StackName.oneCell,
  mainImage: mainImageOneCell,
  renderImage: renderImageOneCell,
  instructionImage: instructionImageOneCell,
  assembly: <AssemblyOneCell />,
};

export const deviceContent: DeviceContent[] = [
  {
    name: StackName.oneCell,
    mainImage: mainImageOneCell,
    renderImage: renderImageOneCell,
    instructionImage: instructionImageOneCell,
    assembly: <AssemblyOneCell />,
  },
  {
    name: StackName.twoCells,
    mainImage: mainImageTwoCells,
    renderImage: renderImageTwoCells,
    instructionImage: instructionImageTwoCells,
    assembly: <AssemblyTwoCells />,
  },
];
