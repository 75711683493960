import { ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import ConfirmationCode from '@src/components/FlowManager/components/ConfirmationCode';
import { ConfirmationCodeFlow } from '@src/components/FlowManager/components/ConfirmationCode/types';
import PhoneNumber from '@src/components/FlowManager/components/PhoneNumber';
import { Path } from '@src/types/routes';

import NewPasswordCreation from '../components/NewPasswordCreation';
import { ERecoveryStep, RecoverySteps } from '../types';

export function useRecoveryStepsHandler(): ReactNode {
  const { recoveryStep } = useParams() as {
    recoveryStep: ERecoveryStep;
  };

  const recoverySteps: RecoverySteps = {
    [ERecoveryStep.phoneNumber]: (
      <PhoneNumber
        nextRoute={`${Path.passwordRecovery}/${ERecoveryStep.confirmationCode}`}
      />
    ),
    [ERecoveryStep.confirmationCode]: (
      <ConfirmationCode
        nextRoute={`${Path.passwordRecovery}/${ERecoveryStep.createPassword}`}
        flow={ConfirmationCodeFlow.passwordRecovery}
      />
    ),
    [ERecoveryStep.createPassword]: <NewPasswordCreation />,
  };

  return recoverySteps[recoveryStep];
}
