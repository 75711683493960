import { css, styled } from 'styled-components';

import ChevronSVG from '@src/assets/icons/chevron.svg';
import SupportSVG from '@src/assets/icons/support.svg';
import { ROUTING_DELAY } from '@src/constants/routing';

import { HeaderContainerProps, RightButtonProps } from './types';

export const ChevronIcon = styled(ChevronSVG)`
  position: absolute;
  left: ${({ theme }): string => theme.spacing(2)};
  path {
    transition-duration: ${ROUTING_DELAY}ms;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const SupportIcon = styled(SupportSVG)``;

export const RightButton = styled.button<RightButtonProps>`
  position: absolute;
  right: ${({ theme }): string => theme.spacing(2)};
  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.xs};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  opacity: ${({ $isActive }): number => ($isActive ? 1 : 0.4)};
  transition-duration: ${ROUTING_DELAY}ms;
  background-color: transparent;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};
`;

function applyHeaderStyle({ $mode }: HeaderContainerProps) {
  return $mode === 'dark'
    ? css`
        ${ChevronIcon} {
          path {
            stroke: ${({ theme }): string => theme.palette.text.primary};
          }
        }
        ${RightButton} {
          color: ${({ theme }): string => theme.palette.text.primary};
        }
      `
    : css`
        ${ChevronIcon} {
          path {
            stroke: ${({ theme }): string => theme.palette.primary};
          }
        }
        ${RightButton} {
          color: ${({ theme }): string => theme.palette.primary};
        }
      `;
}

export const HeaderContainer = styled.header<HeaderContainerProps>`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  ${applyHeaderStyle};
`;
