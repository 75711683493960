import styled, { css } from 'styled-components';

import { CountryListProps } from './types';

function applyListVisibility({ $isShownPicker }: CountryListProps) {
  return $isShownPicker
    ? css`
        transform: translateY(0);
        opacity: 1;
        pointer-events: all;
      `
    : css`
        transform: translateY(-20px);
        opacity: 0;
        pointer-events: none;
      `;
}

export const CountryPickerContainer = styled.div`
  position: relative;
  width: 100%;
`;

export const CountryList = styled.ul<CountryListProps>`
  position: absolute;
  list-style: none;
  top: 100%;
  width: 100%;
  max-height: 300px;
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
  background-color: white;
  gap: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(1)};
  transition-duration: ${({ theme }) => theme.transitionDuration.normal};
  overflow-y: auto;
  z-index: 1;
  padding: ${({ theme }) => theme.spacing(2)};
  ${applyListVisibility};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(1.5px);
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 8px;
`;

export const CountryListItem = styled.li`
  width: inherit;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1.5)};

  font-family: ${({ theme }) => theme.typography.fontFamily.primary};
  font-size: ${({ theme }) => theme.typography.fontSize.xs};
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const CountryFlag = styled.span`
  font-size: 1.8em;
`;

export const CountryInfo = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.spacing()};
`;

export const CountryName = styled.span``;

export const CountryDialCode = styled.span`
  color: ${({ theme }) => theme.palette.text.additional};
`;
