export enum ErrorType {
  phoneNumberAlreadyExist = 'phone_number_already_exist',
  userNotRegistered = 'user_not_registered',
  phoneNumberNotValid = 'phone_number_not_valid',
  incorrectLoginOrPassword = 'incorrect_login_or_password',
  somethingWentWrong = 'something_went_wrong',
  somethingWentWrongWith = 'something_went_wrong_with',
  permissionDenied = 'permission_denied',
  requiredField = 'required_field',
  loadingError = 'loading_error',
  noResultFromBackEnd = 'no_result_from_back_end',
  incorrectQr = 'incorrect_qr',
  routeSheetDoesNotExist = 'route_sheet_does_not_exist',
  itemDoesNotExist = 'item_does_not_exist',
  deviceDoesNotExist = 'device_does_not_exist',
  deviceIdDoesNotExist = 'device_id_does_not_exist',
  toMainPage = 'to_main_page',
  noEmptyCells = 'no_empty_cells',
  ordersRefreshFailure = 'orders_refresh_failure',
  stackNotCalibrated = 'stack_not_calibrated',
  thereAreOpenCells = 'there_are_open_cells',
  smthWrongWithCell = 'Something went wrong with cell available',
  smthWrongWithStack = 'Something went wrong with stack',
  smthWrongWithOrder = 'Something went wrong with order stack or order cell',
  invalidConfirmationCode = 'invalid_confirmation_code',
  deviceOffline = 'device_offline',
  needPasswordChange = 'need_password_change',
  aborted = 'aborted',
  multiPlacingParent = 'multi_placing_parent',
  uploadPhotoError = 'upload_photo_error',
  incorrectPassword = 'incorrect_password',
  deviceUidDoesNotExist = 'device_uid_does_not_exist',
}

export interface ErrorHandlerContextValue {
  handleError: (errorType: ErrorType) => void;
}
