import { useCallback, useRef } from 'react';

import Input from '@src/components/Input';
import { InputRef } from '@src/components/Input/types';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';
import { ActionType } from '@src/contexts/UserInfoContext/types';
import { useDelayNavigation } from '@src/hooks/useDelayNavigation';
import { ERegistrationStep } from '@src/pages/Registration/types';
import { Path } from '@src/types/routes';

import { useSimpleFieldsReg } from '../../hooks/useSimleFieldsReg';

import { InputWrapper } from '../../styled';
import { FlowStepWrapper } from '@src/components/FlowManager/styled';

export default function PersonalData(): JSX.Element {
  const [isStarted, navigateWithDelay] = useDelayNavigation();
  const { userInfo } = useUserInfoContext();
  const { tCommon } = useTranslations();

  const firstNameRef = useRef<InputRef>(null);
  const lastNameRef = useRef<InputRef>(null);
  const emailRef = useRef<InputRef>(null);

  const onNextHandler = useCallback(() => {
    if (!firstNameRef.current || !lastNameRef.current || !emailRef.current) {
      return;
    }
    const isEmptyFirstName = firstNameRef.current.isEmpty();
    const isEmptyLastName = lastNameRef.current.isEmpty();
    const isValidEmail = emailRef.current.isEmailValid();

    if (!isEmptyFirstName && !isEmptyLastName && isValidEmail) {
      navigateWithDelay(`${Path.registration}/${ERegistrationStep.address}`);
    }
  }, [navigateWithDelay]);

  const onChangeHandler = useSimpleFieldsReg(isStarted, onNextHandler);

  return (
    <FlowStepWrapper>
      <InputWrapper>
        <Input
          label={tCommon('firstName')}
          value={userInfo.firstName}
          onChange={onChangeHandler(ActionType.setFirstName)}
          ref={firstNameRef}
          required
        />
        <Input
          label={tCommon('lastName')}
          value={userInfo.lastName}
          onChange={onChangeHandler(ActionType.setLastName)}
          ref={lastNameRef}
          required
        />
        <Input
          label={tCommon('email')}
          value={userInfo.email}
          onChange={onChangeHandler(ActionType.setEmail)}
          ref={emailRef}
        />
      </InputWrapper>
    </FlowStepWrapper>
  );
}
