import { useCallback, useRef, useState } from 'react';

import { updateUserInfo } from '@src/api/utils/updateUserInfo';
import Password from '@src/components/Password';
import { PasswordRef } from '@src/components/Password/types';
import { useAuthContext } from '@src/contexts/AuthContext';
import { useErrorHandlerContext } from '@src/contexts/ErrorHandlerContext';
import { useApplyCommonState } from '@src/contexts/FlowManagerContext/hooks/useApplyCommonState';
import { useSmoothlyRoutingContext } from '@src/contexts/SmoothlyRoutingContext';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';
import { ActionType } from '@src/contexts/UserInfoContext/types';
import { Path } from '@src/types/routes';

import { createUser } from '../../utils/createUser';
import { updateQR } from '../../utils/updateQR';
import { TIME_FOR_UPDATE_QR } from './constants';

import { FlowStepWrapper } from '@src/components/FlowManager/styled';

export default function PasswordCreation(): JSX.Element {
  const [isCreating, setIsCreating] = useState(false);
  const { startSmoothlyRouting } = useSmoothlyRoutingContext();
  const { saveToken } = useAuthContext();
  const { handleError } = useErrorHandlerContext();
  const { userInfo, dispatch } = useUserInfoContext();
  const {
    firstName,
    lastName,
    deviceUid,
    email,
    phone,
    country,
    city,
    house,
    street,
    addressDescription,
  } = userInfo;

  const passwordRef = useRef<PasswordRef>(null);

  const createUserHandler = useCallback(() => {
    if (!passwordRef.current) return;
    const passwordValue = passwordRef.current.checkPassword();
    if (!passwordValue || !country) return;

    setIsCreating(true);

    createUser({
      firstName,
      lastName,
      deviceUid,
      email,
      password: passwordValue,
      phone,
      country,
    })
      .then(({ token }) => {
        setTimeout(() => {
          updateQR(deviceUid);
        }, TIME_FOR_UPDATE_QR);

        return token;
      })
      .then((token) => {
        saveToken(token);

        return updateUserInfo({
          token: `Token ${token}`,
          addressDescription,
          contact: {
            firstName,
            lastName,
          },
          address: {
            city,
            street,
            house,
          },
        });
      })
      .then(() => {
        startSmoothlyRouting(Path.user);
      })
      .catch((error) => handleError(error.message))
      .finally(() => setIsCreating(false));
  }, [
    city,
    house,
    phone,
    email,
    street,
    lastName,
    firstName,
    deviceUid,
    country,
    addressDescription,
    saveToken,
    handleError,
    startSmoothlyRouting,
  ]);

  function onChangeHandler(value: string) {
    dispatch({ type: ActionType.setPassword, payload: value });
  }

  useApplyCommonState({
    isLoading: isCreating,
    buttonAction: createUserHandler,
  });

  return (
    <FlowStepWrapper>
      <Password ref={passwordRef} onChange={onChangeHandler} />
    </FlowStepWrapper>
  );
}
