import { useSmoothlyRoutingContext } from '@src/contexts/SmoothlyRoutingContext';
import { useTranslations } from '@src/contexts/TranslationContext';

import { MenuItemProps } from './types';

import { ChevronIcon, Item, Link } from './styled';

export default function MenuItem({
  title,
  url,
  blank,
  onClick,
}: MenuItemProps): JSX.Element {
  const { startSmoothlyRouting } = useSmoothlyRoutingContext();
  const { t } = useTranslations('menu');

  function LinkHandler(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();

    if (blank) {
      return setTimeout(() => {
        window.open(url)?.focus();
      });
    }

    if (url) {
      return startSmoothlyRouting(url);
    }

    if (onClick) {
      return onClick();
    }
  }
  return (
    <Item>
      <Link href={url} onClick={LinkHandler}>
        {t(title)}
        <ChevronIcon />
      </Link>
    </Item>
  );
}
