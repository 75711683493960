import Assembly11 from '@src/assets/images/assembly-two-cells/assembly-11.png';
import { useTranslations } from '@src/contexts/TranslationContext';

import { AssemblyItemType } from '../type';

export default function useEmergency(): AssemblyItemType[] {
  const { t } = useTranslations('assembly');

  return [
    {
      id: 1,
      text: t('emergencyTitle1'),
    },
    {
      id: 2,
      text: t('emergencyTitle2'),
    },
    {
      id: 3,
      text: t('emergencyTitle3'),
    },
    {
      id: 4,
      text: t('emergencyTitle4'),
      image: Assembly11,
    },
  ];
}
