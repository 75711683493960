import {
  createContext,
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';

import { getConfigurationView } from '@src/api/utils/getConfigurationView';
import { DELAY_FOR_ANIMATION } from '@src/contexts/DeviceInfoContext/constants';
import useDeviceContent from '@src/contexts/DeviceInfoContext/hooks/useDeviceContent';
import { useErrorHandlerContext } from '@src/contexts/ErrorHandlerContext';
import { ContextProps } from '@src/contexts/types';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';

import { ConfigurationView, DeviceInfoContextValue, StorageKey } from './types';

const DeviceInfoContext = createContext<DeviceInfoContextValue | null>(null);

export function DeviceInfoContextProvider({
  children,
}: ContextProps): JSX.Element {
  const [deviceUid, setDeviceUid] = useState('');
  const [deviceInfo, setDeviceInfo] = useState<ConfigurationView>();

  const { userInfo } = useUserInfoContext();
  const { handleError } = useErrorHandlerContext();

  const deviceContent = useDeviceContent(deviceInfo?.stacks[0].name);

  const recordDeviceInfo = useCallback((data: ConfigurationView) => {
    sessionStorage.setItem(StorageKey.deviceInfo, JSON.stringify(data));
    setTimeout(() => {
      setDeviceInfo(data);
    }, DELAY_FOR_ANIMATION);
  }, []);

  useLayoutEffect(() => {
    const initDeviceUid = sessionStorage.getItem(StorageKey.deviceUid);
    const initDeviceInfo = sessionStorage.getItem(StorageKey.deviceInfo);

    if (initDeviceUid) {
      setDeviceUid(initDeviceUid);
    }

    if (initDeviceInfo) {
      setDeviceInfo(JSON.parse(initDeviceInfo));
    }
  }, []);

  useLayoutEffect(() => {
    const initDeviceInfo = sessionStorage.getItem(StorageKey.deviceInfo);

    if (userInfo.deviceUid && !initDeviceInfo) {
      getConfigurationView(userInfo.deviceUid)
        .then((res) => recordDeviceInfo(res))
        .catch((error) => handleError(error.message));
    }
  }, [userInfo.deviceUid, recordDeviceInfo, handleError]);

  const value: DeviceInfoContextValue = useMemo(
    () => ({
      deviceUid,
      deviceInfo,
      deviceContent,
    }),
    [deviceUid, deviceInfo, deviceContent],
  );

  return (
    <DeviceInfoContext.Provider value={value}>
      {children}
    </DeviceInfoContext.Provider>
  );
}

export function useDeviceInfoContext(): DeviceInfoContextValue {
  const value = useContext(DeviceInfoContext);

  if (value) return value;

  throw new Error(
    'Please, use useDeviceInfoContext hook inside DeviceInfoContextProvider',
  );
}
