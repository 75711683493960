import FlowManager from '@src/components/FlowManager/FlowManager';
import { useTranslations } from '@src/contexts/TranslationContext';

import { useRegistrationStepsHandler } from './hooks/useRegistrationStepsHandler';

export default function RegistrationStep(): JSX.Element {
  const { tCommon } = useTranslations();

  const registrationStep = useRegistrationStepsHandler();

  return (
    <FlowManager title={tCommon('registration')}>
      {registrationStep}
    </FlowManager>
  );
}
