import {
  ChangeEvent,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import Input from '@src/components/Input';
import { InputRef } from '@src/components/Input/types';
import {
  PhoneInputData,
  PhoneInputRef,
} from '@src/components/PhoneInput/types';
import { TextAreaRef } from '@src/components/TextArea/types';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';

import { inputId } from '../constants';
import { AllInputs, Id, LocalUserInfo } from '../types';

interface UseInputsRenderReturns {
  isActiveSaveButton: boolean;
  localUserInfo: LocalUserInfo;
  phoneRef: RefObject<PhoneInputRef>;
  descriptionRef: RefObject<TextAreaRef>;
  onPhoneChange: (data: PhoneInputData) => void;
  onDescriptionChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  renderInputs: () => ReactNode;
  checkInputs: () => boolean | undefined;
}

export function useInputs(): UseInputsRenderReturns {
  const [changedInputs, setChangedInputs] = useState<AllInputs[]>([]);
  const [localUserInfo, setLocalUserInfo] = useState<LocalUserInfo>({
    firstName: '',
    lastName: '',
    email: '',
    country: undefined,
    phone: '',
    city: '',
    street: '',
    house: '',
    addressDescription: '',
    countryName: '',
  });

  const { tCommon } = useTranslations();
  const { userInfo } = useUserInfoContext();

  const firstNameRef = useRef<InputRef>(null);
  const lastNameRef = useRef<InputRef>(null);
  const emailRef = useRef<InputRef>(null);
  const cityRef = useRef<InputRef>(null);
  const streetRef = useRef<InputRef>(null);
  const houseRef = useRef<InputRef>(null);
  const phoneRef = useRef<PhoneInputRef>(null);
  const descriptionRef = useRef<TextAreaRef>(null);

  function getRef(id: Id): RefObject<InputRef> {
    const refs = {
      [inputId.firstName]: firstNameRef,
      [inputId.lastName]: lastNameRef,
      [inputId.email]: emailRef,
      [inputId.city]: cityRef,
      [inputId.street]: streetRef,
      [inputId.house]: houseRef,
    };

    return refs[id];
  }

  useEffect(() => {
    setLocalUserInfo({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email: userInfo.email,
      country: userInfo.country,
      phone: userInfo.phone,
      city: userInfo.city,
      street: userInfo.street,
      house: userInfo.house,
      addressDescription: userInfo.addressDescription,
      countryName: userInfo.countryName,
    });
  }, [userInfo]);

  const checkInputs = useCallback((): boolean | undefined => {
    if (
      !firstNameRef.current ||
      !emailRef.current ||
      !phoneRef.current ||
      !lastNameRef.current ||
      !cityRef.current ||
      !streetRef.current ||
      !houseRef.current ||
      !descriptionRef.current
    )
      return;

    const isFirstNameEmpty = firstNameRef.current.isEmpty();
    const isLastNameEmpty = lastNameRef.current.isEmpty();
    const isCityEmpty = cityRef.current.isEmpty();
    const isStreetEmpty = streetRef.current.isEmpty();
    const isHouseEmpty = houseRef.current.isEmpty();
    const isDescription = descriptionRef.current.isEmpty();
    const isEmailValid = emailRef.current.isEmailValid();

    const { isNumberCorrect } = phoneRef.current.getPhoneInfo();

    return !!(
      !isFirstNameEmpty &&
      !isLastNameEmpty &&
      !isCityEmpty &&
      !isStreetEmpty &&
      !isHouseEmpty &&
      !isDescription &&
      isEmailValid &&
      isNumberCorrect
    );
  }, []);

  function compareValues(id: AllInputs, value: string): void {
    if (userInfo[id] !== value) {
      setChangedInputs((prev) => [...prev, id]);
    } else {
      setChangedInputs((prev) =>
        prev.filter((inputForChange) => inputForChange !== id),
      );
    }
  }

  function onPhoneChange({ fullNumber, country }: PhoneInputData) {
    setLocalUserInfo((prev) => ({
      ...prev,
      phone: fullNumber,
      country: country.code,
      countryName: country.name,
    }));
    compareValues('phone', fullNumber);
  }

  function onDescriptionChange({ target }: ChangeEvent<HTMLTextAreaElement>) {
    const { value } = target;
    setLocalUserInfo((prev) => ({
      ...prev,
      addressDescription: value,
    }));
    compareValues('addressDescription', value);
  }

  function onChangeInput({ target }: ChangeEvent<HTMLInputElement>) {
    const id = target.id as Id;
    const { value } = target;

    setLocalUserInfo((prev) => ({ ...prev, [id]: value }));
    compareValues(id, value);
  }

  function renderInputs(): ReactNode {
    return Object.values(inputId).map((value) => {
      const id = value as Id;
      return (
        <Input
          id={id}
          key={id}
          ref={getRef(id)}
          type={id === 'email' ? 'email' : 'text'}
          label={tCommon(id)}
          onChange={onChangeInput}
          value={localUserInfo[id]}
          required={id !== 'email'}
        />
      );
    });
  }

  return {
    localUserInfo,
    isActiveSaveButton: !!changedInputs.length,
    phoneRef,
    descriptionRef,
    renderInputs,
    onPhoneChange,
    onDescriptionChange,
    checkInputs,
  };
}
