import { useCallback, useEffect, useState } from 'react';

import { checkConfirmationCode } from '@src/api/utils/checkConfirmationCode';
import { getConfirmationCode } from '@src/api/utils/getConfirmationCode';
import { useAuthContext } from '@src/contexts/AuthContext';
import { useErrorHandlerContext } from '@src/contexts/ErrorHandlerContext';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { useApplyCommonState } from '@src/contexts/FlowManagerContext/hooks/useApplyCommonState';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useUserInfoContext } from '@src/contexts/UserInfoContext';
import { ActionType } from '@src/contexts/UserInfoContext/types';
import { useDelayNavigation } from '@src/hooks/useDelayNavigation';

import AccessCode from './components/AccessCode/AccessCode';
import { CODE_LENGTH } from './components/AccessCode/constants';
import Countdown from './components/Countdown/Countdown';
import { DELAY_TO_SUBMIT } from './constants';
import { ButtonStyled, TextWrapper } from './styles';
import { ConfirmationCodeProps } from './types';

import { FlowStepWrapper } from '../../styled';

export default function ConfirmationCode({
  nextRoute,
  flow,
}: ConfirmationCodeProps): JSX.Element {
  const [isSendingCode, setIsSendingCode] = useState(false);

  const [isStarted, navigateWithDelay] = useDelayNavigation();
  const { time, isTimerStarted, startCountdown } = useFlowManagerContext();
  const {
    userInfo: { phone, deviceUid, confirmationCode },
    dispatch,
  } = useUserInfoContext();
  const { tCommon } = useTranslations();
  const { handleError } = useErrorHandlerContext();
  const { saveBearerToken } = useAuthContext();

  const isValidCode = confirmationCode?.length === CODE_LENGTH;

  useEffect(() => {
    // Clear confirmation code on mount
    dispatch({
      type: ActionType.setConfirmationCode,
      payload: '',
    });
  }, [dispatch]);

  function resendCode() {
    setIsSendingCode(true);
    getConfirmationCode({
      phone,
      deviceUid,
    })
      .then(() => startCountdown())
      .catch((error) => handleError(error.message))
      .finally(() => setIsSendingCode(false));
  }

  const sendConfirmationCodeHandler = useCallback(() => {
    setIsSendingCode(true);
    checkConfirmationCode({
      flow,
      phone,
      deviceUid,
      code: confirmationCode,
    })
      .then((data) => {
        // This block will be fulfilled only when flow === 'passwordRecovery'
        if (data) {
          saveBearerToken(data.token_data);
        }
        navigateWithDelay(nextRoute);
      })
      .catch((error) => handleError(error.message))
      .finally(() => setIsSendingCode(false));
  }, [
    nextRoute,
    flow,
    deviceUid,
    phone,
    confirmationCode,
    handleError,
    navigateWithDelay,
    saveBearerToken,
  ]);

  useApplyCommonState({
    isActive: isValidCode,
    isLoading: isSendingCode,
    isStarted,
    buttonAction: sendConfirmationCodeHandler,
  });

  function onChangeCodeHandler(code: string) {
    dispatch({
      type: ActionType.setConfirmationCode,
      payload: code,
    });
  }

  useEffect(() => {
    if (!isValidCode) return;

    const timeToSendCode = setTimeout(() => {
      sendConfirmationCodeHandler();
    }, DELAY_TO_SUBMIT);

    return () => {
      clearTimeout(timeToSendCode);
    };
  }, [isValidCode, sendConfirmationCodeHandler]);

  return (
    <FlowStepWrapper>
      <AccessCode onChangeCode={onChangeCodeHandler} />
      {isTimerStarted && <Countdown time={time} />}
      <TextWrapper $isActive={!isTimerStarted}>
        {tCommon('didNotReceiveTheCode')}
        <ButtonStyled onClick={() => resendCode()} disabled={isTimerStarted}>
          {tCommon('requestAgain')}
        </ButtonStyled>
      </TextWrapper>
    </FlowStepWrapper>
  );
}
