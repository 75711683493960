import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTING_DELAY } from '@src/constants/routing';

// This hook is used for animate components between routes
// You should use if with animateOpacity function from src/theme/animation.ts
export function useDelayNavigation(): [boolean, (path: string) => void] {
  const [isStarted, setIsStarted] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigateWithDelay = useCallback(
    (path: string) => {
      setIsStarted(true);
      setTimeout(() => {
        navigate(path);
      }, ROUTING_DELAY);
    },
    [navigate],
  );

  useEffect(() => {
    setIsStarted(false);
  }, [pathname]);

  return [isStarted, navigateWithDelay];
}
