import {
  CommonThemeData,
  ThemePalette,
  ThemeTypography,
} from 'styled-components';

import { COLORS } from './colors';

export const themeTypography: ThemeTypography = {
  typography: {
    fontFamily: {
      primary: 'DM Sans, sans-serif',
      secondary: 'Sohne Breit, sans-serif',
    },
    fontWeight: {
      400: '400',
      500: '500',
      700: '700',
    },
    fontSize: {
      xxl: '1.4em', // 28px according markup
      xl: '1.2em', // 24px according markup
      l: '1.1em', // 22px according markup
      m: '1em', // 20px according markup (100%)
      s: '0.9em', // 18px according markup
      xs: '0.8em', // 16px according markup
      xxs: '0.6em', // 12px according markup
    },
    letterSpacing: {
      l: '0.1px',
      m: '0.084px',
      s: '0.04px',
      xs: '0.036px',
      xxs: '0.032px',
    },
  },
};

export const commonThemeData: CommonThemeData = {
  shape: {
    borderRadius: {
      l: '100vmax',
    },
  },
  transitionDuration: {
    normal: '0.3s',
  },
  spacing: (value = 1) => `${value * 8}px`,
};

export const defaultPalette: ThemePalette = {
  palette: {
    primary: COLORS.yellow[100],
    secondary: COLORS.grey[100],
    grey: COLORS.grey,
    green: COLORS.green,
    error: COLORS.red,
    background: {
      primary: COLORS.grey[5],
      secondary: COLORS.grey[100],
      special: COLORS.yellow[100],
      white: COLORS.grey[0],
    },
    text: {
      primary: COLORS.grey[100],
      secondary: COLORS.grey[5],
      special: COLORS.yellow[100],
      additional: COLORS.grey[30],
      white: COLORS.grey[0],
    },
  },
};
