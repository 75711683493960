import { useNavigate } from 'react-router-dom';

import { useHeaderSettingsContext } from '@src/contexts/HeaderSettingsContext';
import { useTranslations } from '@src/contexts/TranslationContext';

import { HeaderProps } from './types';

import {
  ChevronIcon,
  HeaderContainer,
  RightButton,
  SupportIcon,
} from './styled';

export default function Header({ mode }: HeaderProps): JSX.Element {
  const navigate = useNavigate();

  const { headerSettings } = useHeaderSettingsContext();
  const { tCommon } = useTranslations('omniHomeLaunching');

  return (
    <HeaderContainer $mode={mode}>
      {headerSettings.isVisibleBackButton && (
        <ChevronIcon role="button" onClick={() => navigate(-1)} />
      )}
      {headerSettings.RightButtonTitle && (
        <RightButton
          type="button"
          disabled={!headerSettings.isActiveRightButton}
          $isActive={!!headerSettings.isActiveRightButton}
          onClick={headerSettings.RightButtonAction}
        >
          {headerSettings.RightButtonTitle}

          {headerSettings.RightButtonTitle === tCommon('support') && (
            <SupportIcon />
          )}
        </RightButton>
      )}
    </HeaderContainer>
  );
}
