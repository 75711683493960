import styled, { css } from 'styled-components';

import { MAX_HEIGHT_TEXTAREA, MIN_HEIGHT_TEXTAREA } from '@src/constants/sizes';
import { Label, textCommon } from '@src/theme/commonElements';
import { LabelProps } from '@src/theme/commonTypes';

function applyTextAreaPlaceholderVisibility({ $isLabelShown }: LabelProps) {
  return $isLabelShown
    ? css`
        transform: translate(
          ${({ theme }): string => theme.spacing(1.5)},
          calc(100% + ${({ theme }): string => theme.spacing(1.5)})
        );
      `
    : css`
        transform: translate(0%, 0%);
      `;
}

export const TextAreaLabel = styled(Label)`
  ${applyTextAreaPlaceholderVisibility};
`;

export const StyledTextArea = styled.textarea`
  display: block;
  width: 100%;
  min-height: ${MIN_HEIGHT_TEXTAREA}px;
  max-height: ${MAX_HEIGHT_TEXTAREA}px;
  padding: ${({ theme }): string => theme.spacing(1.5)};
  background-color: transparent;
  border: 1px solid ${({ theme }): string => theme.palette.grey[100]};
  overflow: hidden;
  outline: none;
  resize: none;
  ${textCommon};

  &::placeholder {
    font-size: ${({ theme }): string => theme.typography.fontSize.xs};
    color: ${({ theme }): string => theme.palette.text.primary};
    opacity: 0.6;
  }

  &:focus {
    outline: none;
  }
`;
