import { Languages } from './types';

export const languages: Languages = {
  uk: {
    lang: 'uk',
    htmlCode: 'uk-UA',
    short3: 'УКР',
  },
  en: {
    lang: 'en',
    htmlCode: 'en-US',
    short3: 'ENG',
  },
  // he: {
  //   lang: 'he',
  //   htmlCode: 'he-IL',
  //   short3: 'עִברִית',
  //   direction: 'rtl',
  // },
  // kk: {
  //   lang: 'kk',
  //   htmlCode: 'kk-KZ',
  //   short3: 'ҚАЗ',
  // },
  // ka: {
  //   lang: 'ka',
  //   htmlCode: 'ka-GE',
  //   short3: 'ქარ',
  // },
  // it: {
  //   lang: 'it',
  //   htmlCode: 'it-IT',
  //   short3: 'ITA',
  // },
  // th: {
  //   lang: 'th',
  //   htmlCode: 'th-TH',
  //   short3: 'THA',
  // },
  // ru: {
  //   lang: 'ru',
  //   htmlCode: 'ru-RU',
  //   short3: 'РУС',
  // },
};
