import {
  ChangeEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
  useTransition,
} from 'react';

import Input from '@src/components/Input';
import { useTranslations } from '@src/contexts/TranslationContext';
import { useOutsideClick } from '@src/hooks/useOutsideClick';
import { formattingString } from '@src/utils/formattingString';

import { useUserDataFromIP } from '../../hooks/useUserDataFromIP';
import { countries } from './countries';
import { Country, CountryFullData, CountryPickerProps } from './types';

import {
  CountryDialCode,
  CountryFlag,
  CountryInfo,
  CountryList,
  CountryListItem,
  CountryName,
  CountryPickerContainer,
} from './styled';

export default function CountryPicker({
  initialCountry,
  onCountrySelect: sendCountryData,
}: CountryPickerProps): JSX.Element {
  const [isShownPicker, setIsShownPicker] = useState(false);
  const [value, setValue] = useState('');
  const [selectedCountry, setSelectedCountry] = useState<Country>();
  const [, startTransition] = useTransition();
  const countryPickerRef = useRef<HTMLDivElement>(null);

  const { currentLang, tCommon } = useTranslations();
  const getCountryCodeFromIP = useUserDataFromIP();

  const filteredList = useMemo(
    () =>
      countries.filter((country) =>
        country.name[currentLang]
          .toLowerCase()
          .includes(formattingString(value.toLowerCase(), 'withSpaces')),
      ),
    [currentLang, value],
  );

  const listToRender = filteredList.length ? filteredList : countries;

  function closeCountryList() {
    setIsShownPicker(false);
  }

  useOutsideClick({
    ref: countryPickerRef,
    isOpen: isShownPicker,
    cb: closeCountryList,
  });

  function textChangeHandler(event: ChangeEvent<HTMLInputElement>) {
    const { value: text } = event.target;
    startTransition(() => {
      setValue(text);
    });
  }

  function onSearchFieldClick() {
    setValue('');
    setIsShownPicker(true);
  }

  const onCountrySelect = (country: Country) => () => {
    setSelectedCountry(country);
    sendCountryData(country, true);
    closeCountryList();
  };

  useEffect(() => {
    (async () => {
      const countyToPick =
        initialCountry || (await getCountryCodeFromIP())?.country;

      const defaultCountry =
        (countyToPick && countries.find(({ code }) => code === countyToPick)) ||
        (countries.find(({ code }) => code === 'US') as CountryFullData);

      const country = {
        ...defaultCountry,
        name: defaultCountry.name[currentLang],
      };

      setSelectedCountry(country);
      sendCountryData(country);
    })();
    // We should to disable this rule because we need to run this effect only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCountry, currentLang, getCountryCodeFromIP]);

  return (
    <CountryPickerContainer ref={countryPickerRef}>
      <Input
        value={isShownPicker ? value : selectedCountry?.name || ''}
        onChange={textChangeHandler}
        label={tCommon('country')}
        onClick={onSearchFieldClick}
        required
      />
      <CountryList $isShownPicker={isShownPicker}>
        {listToRender.map((country) => {
          const name = country.name[currentLang];
          const { flag, dialCode } = country;
          return (
            <CountryListItem
              key={country.code}
              onClick={onCountrySelect({ ...country, name })}
            >
              <CountryFlag>{flag}</CountryFlag>
              <CountryInfo>
                <CountryName>{name}</CountryName>
                <CountryDialCode>{dialCode}</CountryDialCode>
              </CountryInfo>
            </CountryListItem>
          );
        })}
      </CountryList>
    </CountryPickerContainer>
  );
}
