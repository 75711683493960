export enum Path {
  home = '/',
  instruction = '/instruction',
  registration = '/registration',
  passwordRecovery = '/password-recovery',
  changePassword = '/change-password',
  authorization = '/authorization',
  user = '/user',
  menu = '/menu',
  howToUse = '/how-to-use',
}

export enum UserPath {
  info = 'info',
}
