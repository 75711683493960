import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { MAX_HEIGHT_TEXTAREA } from '@src/constants/sizes';
import { useTranslations } from '@src/contexts/TranslationContext';
import {
  FieldContainer,
  InfoContainer,
  InfoText,
} from '@src/theme/commonElements';

import { DEFAULT_ERROR } from './constants';
import { TextAreaError, TextAreaProps, TextAreaRef } from './types';

import { StyledTextArea, TextAreaLabel } from './styled';

const TextArea = forwardRef(
  (
    {
      label,
      infoMessage,
      value,
      placeholder: incomingPlaceholder,
      required,
      mode = 'dark',
      id: propId,
      ...textareaProps
    }: TextAreaProps,
    ref?: ForwardedRef<TextAreaRef>,
  ): JSX.Element => {
    const [isOnFocus, setIsOnFocus] = useState(false);
    const [{ isError, errorMessage }, setError] =
      useState<TextAreaError>(DEFAULT_ERROR);

    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const id = useId();

    const elementId = propId || id;

    const placeholder = isOnFocus || !label ? incomingPlaceholder : undefined;
    const isLabelShown = !isOnFocus && !value?.trim();
    const isShowFieldExtraInfo = !!infoMessage || (isError && !!errorMessage);

    const { tCommon } = useTranslations();

    useImperativeHandle(
      ref,
      () => {
        return {
          isEmpty: () => {
            const isEmpty = !value?.trim();
            if (!!required && isEmpty) {
              setError({
                isError: true,
                errorMessage: tCommon('cannotBeEmpty'),
              });
            }
            return !!required && isEmpty;
          },
        };
      },
      [required, value, tCommon],
    );

    useEffect(() => {
      if (textareaRef && textareaRef.current) {
        textareaRef.current.style.height = '0px';
        const { scrollHeight } = textareaRef.current;
        textareaRef.current.style.height = `${scrollHeight}px`;
        if (
          parseFloat(textareaRef.current.style.height) > MAX_HEIGHT_TEXTAREA
        ) {
          textareaRef.current.style.overflowY = 'auto';
        } else {
          textareaRef.current.style.overflowY = 'hidden';
        }
      }

      setError(DEFAULT_ERROR);
    }, [value]);

    return (
      <FieldContainer $isError={isError} $mode={mode}>
        {label && (
          <TextAreaLabel htmlFor={elementId} $isLabelShown={isLabelShown}>
            {label} {required && <sup>*</sup>}
          </TextAreaLabel>
        )}
        <StyledTextArea
          ref={textareaRef}
          id={elementId}
          value={value}
          placeholder={placeholder}
          required={required}
          onFocus={() => {
            setIsOnFocus(true);
          }}
          onBlur={() => {
            setIsOnFocus(false);
          }}
          {...textareaProps}
        />
        {isShowFieldExtraInfo && (
          <InfoContainer>
            <InfoText>{errorMessage || infoMessage}</InfoText>
          </InfoContainer>
        )}
      </FieldContainer>
    );
  },
);

export default TextArea;
