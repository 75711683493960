import { request } from '@src/api';
import { UpdateUserRequest, UserInfoResponse } from '@src/api/types';

interface UpdateUserInfoArgs {
  token: string;
  addressDescription: string;
  contact?: {
    firstName?: string;
    lastName?: string;
    phone?: string;
    email?: string;
  };
  address?: {
    countryName?: string;
    countryCode?: string;
    city?: string;
    street?: string;
    house?: string;
  };
}

export async function updateUserInfo(
  userInfo: UpdateUserInfoArgs,
): Promise<UserInfoResponse> {
  const { contact, address, addressDescription } = userInfo;
  const isUserName = contact?.firstName && contact?.lastName;

  const { data } = await request<UserInfoResponse, UpdateUserRequest>({
    url: 'user/company/',
    method: 'PUT',
    headers: {
      Authorization: userInfo.token,
    },
    data: {
      country_code: address?.countryCode,
      description: addressDescription,
      contact: {
        name: isUserName
          ? `${contact?.firstName} ${contact?.lastName}`
          : undefined,
        phone: contact?.phone,
        email: contact?.email,
      },
      addresses: [
        {
          type: 'physical',
          region: address?.countryName,
          city: address?.city,
          street: address?.street,
          house: address?.house,
        },
      ],
    },
  });

  return data;
}
