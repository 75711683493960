import Button from '@src/components/Button/Button';
import { useFlowManagerContext } from '@src/contexts/FlowManagerContext';
import { ContentContainer, TopWrapper } from '@src/theme/commonElements';

import { FlowManagerProps } from './types';

import { FadingContainer, Title } from './styled';

export default function FlowManager({
  title,
  children,
}: FlowManagerProps): JSX.Element {
  const { buttonState, isRouteChanging } = useFlowManagerContext();

  return (
    <ContentContainer>
      <TopWrapper>
        <Title>{title}</Title>
        <FadingContainer $isStarted={isRouteChanging}>
          {children}
        </FadingContainer>
      </TopWrapper>
      <Button
        isActive={buttonState.isActive}
        isLoading={buttonState.isLoading}
        animation="slideTop"
        variant="contained"
        mode="dark"
        onClick={buttonState.buttonAction}
      >
        {buttonState.text}
      </Button>
    </ContentContainer>
  );
}
