import { request } from '@src/api';
import { OwnerInfo } from '@src/api/types';

export async function getCurrentData(token: string): Promise<OwnerInfo> {
  const { data } = await request<OwnerInfo>({
    url: 'users/current/',
    headers: {
      Authorization: token,
    },
  });

  return data;
}
