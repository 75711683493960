import FlowManager from '@src/components/FlowManager/FlowManager';
import { useTranslations } from '@src/contexts/TranslationContext';

import { useRecoveryStepsHandler } from './hooks/useRecoveryStepsHandler';

export default function PasswordRecovery(): JSX.Element {
  const { tCommon } = useTranslations();
  const recoveryStep = useRecoveryStepsHandler();

  return (
    <FlowManager title={tCommon('passwordRecovery')}>
      {recoveryStep}
    </FlowManager>
  );
}
