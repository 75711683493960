import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  appearing,
  applyAnimation,
  buttonClickAnimation,
  slideBottom,
  slideTop,
} from '@src/theme/animation';

import { AnimatedWrapperProps, ButtonStyledProps } from './types';

function applyButtonAnimation({ $animation }: AnimatedWrapperProps) {
  if ($animation === 'slideBottom') {
    return applyAnimation(slideBottom);
  }
  if ($animation === 'slideTop') {
    return applyAnimation(slideTop);
  }
  if ($animation === 'appear') {
    return applyAnimation(appearing);
  }
}

function applyButtonStyle({ theme, $mode, $variant }: ButtonStyledProps) {
  if ($variant === 'clear') {
    return css`
      background-color: transparent;
      color: ${$mode === 'primary'
        ? theme.palette.text.secondary
        : theme.palette.text.primary};
    `;
  }
  if ($variant === 'contained') {
    return css`
      background-color: ${$mode === 'primary'
        ? theme.palette.primary
        : theme.palette.grey[100]};
      border: none;
      color: ${$mode === 'primary'
        ? theme.palette.text.primary
        : theme.palette.text.secondary};
    `;
  }
  if ($variant === 'outlined') {
    return css`
      background-color: transparent;
      border: 1px solid
        ${$mode === 'primary' ? theme.palette.primary : theme.palette.grey[100]};
      color: ${$mode === 'primary'
        ? theme.palette.primary
        : theme.palette.grey[100]};
    `;
  }
}

export const AnimatedWrapper = styled.div<AnimatedWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  ${applyButtonAnimation};
`;

export const ButtonCommon = styled.button<ButtonStyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 320px;
  height: 56px;

  font-family: ${({ theme }): string => theme.typography.fontFamily.primary};
  font-size: ${({ theme }): string => theme.typography.fontSize.m};
  font-weight: ${({ theme }): string => theme.typography.fontWeight[500]};
  border-radius: 28px;
  box-sizing: border-box;

  pointer-events: ${({ $isActive }): string => ($isActive ? 'all' : 'none')};

  transition-duration: ${({ theme }): string =>
    theme.transitionDuration.normal};

  ${applyButtonStyle};
  opacity: ${({ $isActive }): string => ($isActive ? '1' : '0.2')};

  ${buttonClickAnimation};
`;

export const StyledA = styled.a`
  text-decoration: none;
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const ButtonsCollection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(2)};
`;
