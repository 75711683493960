import styled from 'styled-components';

export const CountdownContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SVGCircle = styled.svg`
  transform: rotate(90deg);
  circle {
    fill: none;
    stroke: ${({ theme }) => theme.palette.grey[5]};
    transition-duration: 1s;
    transition-timing-function: linear;

    &:nth-child(2) {
      stroke: ${({ theme }) => theme.palette.green[30]};
    }
  }
`;

export const CountdownText = styled.span`
  position: absolute;
  font-family: ${({ theme }) => theme.typography.fontFamily.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeight[400]};
  color: ${({ theme }) => theme.palette.text.primary};
  letter-spacing: ${({ theme }) => theme.typography.letterSpacing.m};
  font-size: 42px;
`;
