import { request } from '@src/api';
import { ConfigurationView } from '@src/contexts/DeviceInfoContext/types';

export async function getConfigurationView(
  uid: string,
): Promise<ConfigurationView> {
  const { data } = await request<ConfigurationView>({
    url: `device/${uid}/configuration/view/`,
  });

  return data;
}
