import { Outlet } from 'react-router-dom';

import Header from '@src/components/Header';
import { SmoothlyRoutingContextProvider } from '@src/contexts/SmoothlyRoutingContext';

import { useHeaderDisplaying } from './hooks/useHeaderDisplaying';
import { useSaveQuery } from './hooks/useSaveQuery';
import { useSmoothlyChangeBg } from './hooks/useSmoothlyChangeBg';

import { Cover, MainWrapper } from './styled';

export default function MainContentWrapper() {
  const { isStarted, coverColor, mainBgColor, startSmoothlyRouting } =
    useSmoothlyChangeBg();
  const isShowHeader = useHeaderDisplaying();

  useSaveQuery();

  return (
    <MainWrapper $nextPageBg={mainBgColor}>
      <Cover $isStarted={isStarted} $nextPageBg={coverColor} />
      {isShowHeader && (
        <Header mode={mainBgColor === 'dark' ? 'primary' : 'dark'} />
      )}
      <SmoothlyRoutingContextProvider value={{ startSmoothlyRouting }}>
        <Outlet />
      </SmoothlyRoutingContextProvider>
    </MainWrapper>
  );
}
