export enum Step {
  registration = 'registration',
}

export type Direction = 'next' | 'prev';
export type ColorMode = 'dark' | 'primary' | 'light';

export interface TextAreaProps {
  value?: string;
  placeholder?: string;
  required?: boolean;
}
