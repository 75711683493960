import Assembly5 from '@src/assets/images/assembly-two-cells/assembly-5.png';
import Assembly6 from '@src/assets/images/assembly-two-cells/assembly-6.png';
import Assembly7 from '@src/assets/images/assembly-two-cells/assembly-7.png';
import Assembly8 from '@src/assets/images/assembly-two-cells/assembly-8.png';
import Assembly9 from '@src/assets/images/assembly-two-cells/assembly-9.png';
import Assembly10 from '@src/assets/images/assembly-two-cells/assembly-10.png';
import { useTranslations } from '@src/contexts/TranslationContext';

import { AssemblyItemType } from '../type';

export default function useInstallation(): AssemblyItemType[] {
  const { t } = useTranslations('assembly');

  return [
    {
      id: 1,
      text: t('installationTitle1'),
      image: Assembly5,
    },
    {
      id: 2,
      text: t('installationTitle2'),
      image: Assembly6,
    },
    {
      id: 3,
      text: t('installationTitle3'),
      subPoints: [t('installationSubTitle1'), t('installationSubTitle2')],
      image: Assembly7,
    },
    {
      id: 4,
      text: t('installationTitle4'),
      image: Assembly8,
    },
    {
      id: 5,
      text: t('installationTitle5'),
      image: Assembly9,
    },
    {
      id: 6,
      text: t('installationTitle6'),
      image: Assembly10,
    },
  ];
}
