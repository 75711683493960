import {
  ChangeEvent,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useTranslations } from '@src/contexts/TranslationContext';

import Input from '../Input';
import { InputRef } from '../Input/types';
import CountryPicker from './components/CountryPicker/CountryPicker';
import { Country } from './components/CountryPicker/types';
import { useCheckIsError } from './hooks/useCheckIsError';
import { PhoneInputProps, PhoneInputRef } from './types';
import { formatePhoneNumber } from './utils/formatePhoneNumber';

import { PhoneInputContainer } from './styled';

const PhoneInput = forwardRef<PhoneInputRef, PhoneInputProps>(
  (
    { initialNumber, initialCountry, onChange: sendPhoneData },
    ref,
  ): JSX.Element => {
    const { tCommon } = useTranslations();

    const [numberValue, setNumberValue] = useState('');
    const [isInitialNumberSet, setIsInitialNumberSet] = useState(false);
    const [countryData, setCountryData] = useState<Country>();

    const inputRef = useRef<InputRef>(null);

    const checkIsError = useCheckIsError(inputRef, numberValue, countryData);

    const selectCountryHandler = useCallback(
      (country: Country, isManualChange?: boolean) => {
        setCountryData(country);
        if (isManualChange) {
          setNumberValue('');
          sendPhoneData?.({
            country,
            number: '',
            fullNumber: '',
          });
        }
      },
      [sendPhoneData],
    );

    useEffect(() => {
      if (!countryData || !initialNumber || isInitialNumberSet) return;

      const processedInitialNumber = initialNumber.includes('+')
        ? initialNumber.slice(1)
        : initialNumber; // +380123456789 -> 380123456789
      const dialCodeWithoutPlus = countryData.dialCode.slice(1); // +380 -> 380
      const initialNumberWithoutDialCode = processedInitialNumber.slice(
        dialCodeWithoutPlus.length,
      ); // 380123456789 -> 123456789

      setNumberValue(initialNumberWithoutDialCode);
      setIsInitialNumberSet(true);
    }, [countryData, initialNumber, isInitialNumberSet]);

    function onChangeHandler(event: ChangeEvent<HTMLInputElement>) {
      const { value } = event.target;
      if (!countryData) return;

      const { phoneLength } = countryData;
      const formattedValue = formatePhoneNumber(value);
      const slicedValue = formattedValue.slice(0, phoneLength);

      setNumberValue(slicedValue);
      sendPhoneData?.({
        number: slicedValue,
        fullNumber: `${countryData.dialCode}${slicedValue}`,
        country: countryData,
      });
    }

    useImperativeHandle(
      ref,
      () => {
        return {
          getPhoneInfo() {
            const isNumberCorrect = checkIsError();
            return {
              isNumberCorrect,
              phoneNumber: `${countryData?.dialCode}${numberValue}`,
              country: countryData,
            };
          },
        };
      },
      [checkIsError, countryData, numberValue],
    );

    return (
      <PhoneInputContainer>
        <CountryPicker
          onCountrySelect={selectCountryHandler}
          initialCountry={initialCountry}
        />
        <Input
          ref={inputRef}
          value={numberValue}
          onChange={onChangeHandler}
          phoneCode={countryData?.dialCode}
          label={tCommon('number')}
          type="tel"
          required
        />
      </PhoneInputContainer>
    );
  },
);

export default PhoneInput;
